<template>
  <div class="manageDevice">
    <div
      class="changeSpace"
      v-if="isManageWorkspace">
      <div class="left spaceName">
        {{$t('lang.workspace')}}
      </div>
      <div class="left selectDown">
        <el-select
          class="select select-header pawan"
          placeholder="Select"
          v-model="workspaceListCurrent"
          @change="changeRouter"
          @visible-change="showHideVi"
          value-key="id">
          <el-option
            class="default"
            :key="'default'"
            :label="$t('lang.allDevice')"
            :value="'default'"
            :title="$t('lang.allDevice')"></el-option>
          <div style="height: 160px;overflow-x: hidden;overflow-y: scroll">
            <el-option
              v-for="item in workspaceListArr"
              :key="item.id"
              :label="item.name"
              :value="item"
              :title="item.name"></el-option>
          </div>
          <el-option
            :key="'manageWorkspace'"
            :label="$t('lang.ManageWorkspace')"
            :value="'manageWorkspace'"
            :title="$t('lang.ManageWorkspace')"
            class="bottom"></el-option>
        </el-select>
      </div>

      <div class="right btn_green">
        <router-link
          :to="{name:'MultiView'}"
          :title="$t('lang.tomultiview')">
          {{$t('lang.tomultiview')}}
        </router-link>
      </div>
    </div>
    <div
      class="backtoview"
      v-if="!isManageWorkspace"
      :title="$t('lang.BacktoView')">
      <span @click="backToView()">
        <img
          src="../../assets/img/back.png"
          alt="Back" /> {{$t('lang.BacktoView')}}
      </span>
    </div>

    <div class="manageDevice-content">
      <div class="left availableDevice">

        <div class="filter_section">
          <div class="left">
            {{$t('lang.availableDevice')}} ({{interfaceData.length}})
          </div>
          <div class="left searchDevice">
            <search-input
              :placeholder="$t('lang.searchTText')"
              :callback="search"
              :value="filter.condition"></search-input>
          </div>

          <div class="left statusDevice">
            <div
              class="statusBox left"
              v-show='isShow.filterItem'>
              <span class="statusName left">{{$t('lang.status')}}:</span>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                class="allStatus left">
                {{$t('lang.all')}}
              </el-checkbox>
              <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange"
                class="statusCheckBox">
                <el-checkbox
                  v-for="filter in filterOptionObj"
                  :label="filter"
                  class="statusCheck"
                  :class="{
                    red:filter == 'live',
                    green:filter == 'online',
                    lightGrey:filter == 'offline',
                  }"
                  :key="filter">{{$t(`lang.${filter}`)}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="right paginationDevice">
            <Pagination
              @current-change="handleCurrentChange"
              :current-page="filter.pageNum"
              :pageSizes="[4, 6, 9, 12, 16, 25]"
              :pageSize="filter.pageSize"
              :total="total"
              @size-change="handleSizeChange"
              layout="prev, pageF, next, pageL, nowSize, jumper">
            </Pagination>
          </div>
        </div>

        <!--可用的device 分页-->
        <div
          class="available-content"
          v-loading='loading'
          element-loading-background="rgba(0, 0, 0, 0.8)">
          <draggable
            class="grid_section"
            v-model="interfaceData"
            handle=".handle"
            @change="changeHandleHomeSort"
            :group="{ name: 'currentDevice', put: true }">
            <div
              class="left list"
              v-for="item in interfaceData"
              :key="item.id">
              <device-list
                :interFaceItem="item"
                :isWorkspace="false"
                :splitSocketInfo='allRSocketInfo[item.peerId]'
                :isManageWorkspaceCome="isManageWorkspace"></device-list>
            </div>
          </draggable>
        </div>

      </div>

      <div class="left splitpanes__splitter"></div>

      <!--编辑device-->
      <div
        class="left currentDevice"
        v-if="!isManageWorkspace">
        <div class="filter_section">
          <div class="left currentDeviceD">
            {{$t('lang.currentDevice')}} ({{currentDevice.length}})
          </div>
          <div class="right searchCuDevice">
            <div class="searchDevice">
              <search-input
                :placeholder="$t('lang.searchTText')"
                :callback="searchCurrentD"
                :value="filter.condition"></search-input>
            </div>
          </div>
        </div>

        <div
          class="current-content"
          v-loading='loadingC'
          element-loading-background="rgba(0, 0, 0, 0.8)">
          <draggable
            class="grid_section"
            v-model="currentDevice"
            handle=".handle"
            group="currentDevice"
            @change="changeHandleCGS"
            style="width: 80%">
            <div
              class="left list"
              style="height: 161px;"
              v-for="item in currentDevice"
              :key="item.id">
              <device-list
                :interFaceItem="item"
                :isWorkspace="true"
                :currentSpace="currentSpaceInfoCopy"
                :isManageWorkspaceCome="isManageWorkspace"
                :splitSocketInfo='allRSocketInfo[item.peerId]'></device-list>
            </div>
          </draggable>
        </div>

      </div>

      <!--编辑workspace-->
      <div
        class="left currentDevice"
        v-if="isManageWorkspace">
        <div class="filter_section">
          <div class="left currentDeviceD">
            {{$t('lang.workspaceList')}}
            <span
              class="icon iconfont"
              @click="showAddWorkspace">&#xe6d1;</span>
          </div>
          <div class="right searchCuDevice">
            <div class="searchDevice">
              <search-input
                :placeholder="$t('lang.workspaceName')"
                :callback="searchCurrentWorspace"
                :value="filterWorkspace.condition"></search-input>
            </div>
          </div>
        </div>

        <div
          class="current-content"
          v-loading='loadingW'
          element-loading-background="rgba(0, 0, 0, 0.8)">
          <div
            class="left workspace"
            v-for="item in workspaceListArr"
            :key="item.id">
            <workspace-list :workspaceItem="item"></workspace-list>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
/*
 * 本组件主要功能
 * ① 从我的workspace中,跳转到 Manage device页面
 * ② 点击进入Manage Workspace 编辑workspace页面
 *
 */
import SearchInput from '@/components/Search'
import Pagination from '@/components/Pagination'
import DeviceList from './ManageDeviceList'
import WorkspaceList from './WorkSpaceList'
import Bus from '@/assets/js/vueBus.js'
import toTips from '@/assets/js/toTipsObj'
const cityOptions = ['live', 'online', 'offline']

export default {
  props: {
    currentSpaceInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    allRSocketInfo: Array,
    isManageWorkspace: Boolean,
    workspaceList: {
      type: Array,
      default: () => {
        return []
      }
    },
    loadingSpaceList: Boolean
  },
  data () {
    return {
      filter: { // 所有设置搜索时的参数
        live: true,
        online: true,
        offline: true,
        condition: '',
        pageNum: 1,
        pageSize: 25,
        id: this.currentSpaceInfo.id
      },
      filterspaceDevice: { // 搜索space下面的设备参数
        condition: '',
        id: this.currentSpaceInfo.id,
        live: true,
        online: true,
        offline: true,
        sort: 'name'
      },
      filterWorkspace: { // 获取space列表参数
        condition: '',
        pageNum: 1,
        pageSize: 10000
      },
      isShow: {
        filterItem: true
      },
      checkAll: true,
      checkedCities: ['live', 'online', 'offline'],
      cities: cityOptions,
      isIndeterminate: false,
      filterOptionObj: ['live', 'online', 'offline'],
      interfaceData: [], // 接口内数据
      loading: false,
      loadingC: false,
      loadingW: false,
      total: 0,
      currentDevice: [],
      totalCurrentGridSource: 0,
      workspaceListCurrent: 'manageWorkspace',
      workspaceListArr: this.workspaceList,
      currentSpaceInfoCopy: this.currentSpaceInfo
    }
  },
  components: {
    SearchInput,
    Pagination,
    DeviceList,
    WorkspaceList
  },
  created () {
    this.getDevice()
    if (!this.isManageWorkspace) {
      this.getCurrentDevice()
    }
    // 监听重新获取device
    Bus.$on('regetDevice', (item) => {
      this.getCurrentDevice()
    })
    Bus.$on('clearSearchCondi', () => {
      this.filterWorkspace.condition = ''
    })
    Bus.$on('regetSpace', () => {
      this.getCurrentDevice()
    })
  },
  watch: {
    workspaceList (val) {
      this.workspaceListArr = val
    },
    loadingSpaceList (val) {
      this.loadingW = val
    }
  },
  methods: {
    changeRouter (val) { // 选择workspace下拉跳转页面
      Bus.$emit('workspaceChangeSpace', val)
    },
    showHideVi (val) { // 出现下拉
      if (val) {
        this.getWorkspace()
      }
    },
    backToView () {
      this.$emit('hideManage')
    },
    showAddWorkspace () {
      Bus.$emit('addWorkspace', { type: 'createNew' })
    },
    search (val) { // 搜索device
      this.filter.condition = val
      this.filter.pageNum = 1
      this.getDevice()
    },
    searchCurrentD (val) { // 搜索当前的device
      this.filterspaceDevice.condition = val
      this.getCurrentDevice()
    },
    searchCurrentWorspace (val) { // 搜索当前的space
      this.filterWorkspace.condition = val
      this.getWorkspace()
    },
    getCurrentDevice () {
      // 获取当前组内的设备列表
      this.loadingC = true
      this.axios.post('/ccp/tvugm/workspaceDevice/currentDevice', this.filterspaceDevice).then(res => {
        if (res.data.errorCode == '0x0') {
          this.currentDevice = res.data.result
        }
        this.loadingC = false
      }).catch(res => {
        this.loadingC = false
      })
    },
    getDevice () {
      // 获取所有的设备列表
      this.loading = true
      this.axios.post('/ccp/tvugm/workspaceDevice/availableDevice', this.filter).then(res => {
        if (res.data.errorCode == '0x0') {
          // splitDataArr
          this.interfaceData = res.data.result.list
          this.total = res.data.result.total
        }
        this.loading = false
      }).catch(() => {})
    },

    getWorkspace () {
      // 获取工作区列表
      this.loadingW = true
      this.axios.post('/ccp/tvugm/workspace/page', this.filterWorkspace).then(res => {
        if (res.data.errorCode == '0x0') {
          this.workspaceListArr = res.data.result.list
        }
        this.loadingW = false
      }).catch(res => {
        this.loadingW = false
      })
    },
    handleCurrentChange (val) {
      this.filter.pageNum = val
      this.getDevice()
    },
    handleSizeChange (val) {
      this.filter.pageSize = val
      this.filter.pageNum = 1
      this.getDevice()
    },

    handleCheckAllChange (val) { // 全选框的回调事件
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
      this.filter.pageNum = 1
      if (this.checkAll) { // 判断这个全选的框是否被选中然后给接口传参
        this.filter.offline = true // queryRlist的接口参数
        this.filter.online = true // queryRlist的接口参数
        this.filter.live = true // queryRlist的接口参数
      } else {
        this.filter.offline = false
        this.filter.online = false
        this.filter.live = false
      }
      this.getDevice() // 全选时调用getDevice方法
    },
    handleCheckedCitiesChange (value) { // 子组的筛选框
      const checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.filter.pageNum = 1
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      const statusArr = value // 通过索引去把每次筛选的条件传给queryRlist这个接口
      if (statusArr.indexOf('live') > -1) { // 控制live的参数
        this.filter.live = true
      } else {
        this.filter.live = false
      }

      if (statusArr.indexOf('online') > -1) { // 控制online的参数
        this.filter.online = true
      } else {
        this.filter.online = false
      }

      if (statusArr.indexOf('offline') > -1) { // 控制offline的参数
        this.filter.offline = true
      } else {
        this.filter.offline = false
      }
      this.getDevice() // 每次筛选时调用getDevice方法
    },
    changeHandleHomeSort (val) {
    },
    changeHandleCGS: function (evt) {
      if (evt.added && evt.added.newIndex || evt.added && evt.added.newIndex == 0) {
        const param = {
          workspaceId: this.currentSpaceInfo.id,
          devices: evt.added.element.peerId
        }
        this.axios.post('/ccp/tvugm/workspaceDevice/add', param).then(res => {
          if (res.data.errorCode != '0x0') { // 接口错误处理失败情况
            this.interfaceData = this.interfaceData.concat([evt.added.element])
            this.currentDevice.splice(evt.added.newIndex, 1)
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }).catch(() => {})
      } else if (evt.removed && evt.removed.oldIndex || evt.removed && evt.removed.oldIndex == 0) { // 移除
        const removeId = evt.removed.element.peerId
        const param = {
          devices: removeId,
          workspaceId: this.currentSpaceInfo.id
        }

        this.axios.post('/ccp/tvugm/workspaceDevice/delete', param).then(res => {
          if (res.data.errorCode == '0x0') { // Message
          } else if (res.data.errorCode == '82600107') {
            this.$message({
              type: 'warning',
              message: 'Over the maximum quantity'
            })
          } else {
            this.$message({
              type: 'warning',
              message: 'Move failed'
            })
          }
          if (res.data.errorCode != '0x0') { // 接口错误处理失败情况
            this.currentDevice = this.currentDevice.concat([evt.removed.element])
          }
        }).catch(() => {})
      }

      if (this.currentDevice.length <= 0) {
        this.totalCurrentGridSource = this.currentDevice.length
      } else {
        this.totalCurrentGridSource = this.currentDevice.length
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-checkbox__inner {
  border: 2px solid #999;
  background: none;
}

.btn_green {
  height: 36px;
  width: 120px;
  line-height: 36px;
  text-align: center;
  background: #444;
  border-radius: 4px;
  color: #909090;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 22px;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-select-dropdown__item {
  max-width: 178px;
}

.default {
  border-bottom: 1px solid #333;
}

.bottom {
  border-top: 1px solid #333;
}

::v-deep .selectDown {
  max-width: 178px;
  height: 32px;
  line-height: 32px;

  .el-select {
    background: transparent;
  }

  .el-input__inner {
    max-height: 32px;
    margin-top: 24px;
    display: block;
  }
}

.manageDevice {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .changeSpace {
    height: 80px;
    line-height: 80px;
    margin: 0;
    border-bottom: 1px solid #343434;
    overflow: hidden;

    .spaceName {
      line-height: 80px;
      padding-right: 10px;
    }
  }

  .backtoview {
    margin: 20px 0 0 0;
    text-align: left;
    min-width: 1024px !important;
    cursor: pointer;
  }

  .manageDevice-content {
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    .availableDevice {
      width: 65%;
      height: 100%;
      padding-bottom: 30px;
      overflow: hidden;

      .available-content {
        width: 100%;
        height: calc(100% - 145px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;

        .grid_section {
          width: 100%;
        }

        .list {
          width: 20%;
          height: 161px;
          margin: 7px 0;

          /*&:hover {*/
          /*    box-shadow: 0 2px 5px #101010;*/
          /*}*/
        }
      }

      .searchDevice {
        margin-left: 10px;
      }
    }

    .splitpanes__splitter {
      height: 100%;
      background-color: #444;
      width: 2px;
      border-left: transparent;
    }

    .currentDevice {
      width: 34%;
      height: 100%;
      padding-left: 30px;
      padding-bottom: 30px;

      .current-content {
        width: 100%;
        height: calc(100% - 145px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;

        .workspace {
          width: 33.33333333%;
          height: 62px;
        }

        .grid_section {
          width: 100%;
        }

        .list {
          width: 50%;
          margin: 7px 0;
          height: 195px;
        }
      }
    }
  }
}

.filter_section {
  width: 100%;
  height: 94px;
  line-height: 94px;

  .paginationDevice {
    margin-top: 24px;
  }

  .statusDevice {
    margin-left: 10px;
    margin-top: 25.5px;

    .allStatus {
      line-height: 40px;
      margin: 0 10px;
    }

    .statusName {
      line-height: 40px;
    }

    .statusCheckBox {
      float: left;
      line-height: 40px;
    }

    .sortBox {
      line-height: 40px;
      padding-left: 45px;
    }
  }

  .currentDeviceD {
    span {
      margin-left: 20px;
    }

    .iconfont {
      font: 30px/18px Helvetica Neue, Arial, sans-serif;
      color: #ccc;
    }
  }
}

.onlineBg {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/logo.png);
  background-size: 15%;
  background-repeat: no-repeat;
  background-color: #2f3330;
  background-position: 50% 40%;
  /*opacity: 0.6;*/
  position: absolute;
  left: 0;
  right: 0;
}

.offlineBg {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/MVOffline.png);
  background-size: 15%;
  background-repeat: no-repeat;
  background-color: #2f3330;
  background-position: 50% 45%;
  /*opacity: 0.6;*/
  position: absolute;
  left: 0;
  right: 0;
}
</style>
