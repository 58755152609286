import { render, staticRenderFns } from "./ReceiverList.vue?vue&type=template&id=13e58f07&scoped=true"
import script from "./ReceiverList.vue?vue&type=script&lang=js"
export * from "./ReceiverList.vue?vue&type=script&lang=js"
import style0 from "./ReceiverList.vue?vue&type=style&index=0&id=13e58f07&prod&lang=less&scoped=true"
import style1 from "./ReceiverList.vue?vue&type=style&index=1&id=13e58f07&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e58f07",
  null
  
)

export default component.exports