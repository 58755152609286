<template>
  <div class="workspace-list">
    <div class="left">
      <span class="icon iconfont">&#xeb33;</span>
    </div>
    <div class="left spaceName-content">
      <div
        class="spaceName ecllips"
        :title="workspaceItem.name">
        {{workspaceItem.name}}
      </div>
      <div class="layout ecllips">
        ({{workspaceItem.layout}})
      </div>
    </div>
    <div class="left deviceNum">
      {{workspaceItem.deviceNum}}
    </div>

    <div class="list3">
      <el-dropdown
        placement="bottom"
        :divided="true">
        <img
          :src="list3"
          alt="" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleRename">
            <span class="dupli icon iconfont ">&#xe6ea;</span>{{$t('lang.Rename')}}
          </el-dropdown-item>
          <el-dropdown-item @click.native="handleEnetr">
            <span class="dupli icon iconfont">&#xe6b4;</span>{{$t('lang.Enter')}}
          </el-dropdown-item>
          <el-dropdown-item @click.native="handleDuplicate">
            <span class="dupli icon iconfont ">&#xe6fe;</span>{{$t('lang.Duplicate')}}
          </el-dropdown-item>
          <el-dropdown-item @click.native="handleRemove">
            <span class="dupli icon iconfont ">&#xe63e;</span>{{$t('lang.Delete')}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>
<script>
// workspace的列表组件
import Bus from '@/assets/js/vueBus.js'

export default {
  props: {
    workspaceItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      visible: true,
      list3: require('@/assets/img/list-3.png')
    }
  },
  components: {},
  created () {

  },
  methods: {
    handleRename () {
      const spaceInfo = this.workspaceItem
      spaceInfo.type = 'reName'
      Bus.$emit('addWorkspace', spaceInfo)
    },
    handleEnetr () {
      Bus.$emit('workspaceChangeSpace', this.workspaceItem)
    },
    handleDuplicate () {
      const spaceInfo = Object.assign({}, this.workspaceItem)
      spaceInfo.type = 'duplicate'
      spaceInfo.name = this.workspaceItem.name + ' copy'
      Bus.$emit('addWorkspace', spaceInfo)
    },
    handleRemove () {
      this.$confirm(this.$t('lang.deleteSpace'), '', {
        confirmButtonText: this.$t('lang.btn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: 'warning',
        iconClass: 'myIcon'
      }).then(() => {
        const param = {
          id: this.workspaceItem.id
        }
        this.axios.post('/ccp/tvugm/workspace/delete', param).then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            Bus.$emit('regetSpace')
          } else {
            this.$message({
              type: 'error',
              message: this.$t('lang.errorTips')
            })
          }
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dropdown-menu {
  li.el-dropdown-menu__item {
    line-height: 38px !important;
  }
}

.dupli {
  font-size: 12px !important;
  margin-right: 10px;
  line-height: 40px;
}

.workspace-list {
  width: 95%;
  margin: 0 auto;
  padding: 5px 0px;
  height: 52px;
  background: #333;
  border-radius: 2px;
  border: 1px solid #444;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  .list3 {
    position: absolute;
    right: 5px;
    top: 10px;
    display: none;
  }

  &:hover {
    border: 1px solid #33ab4f;
  }

  &:hover .list3 {
    display: block;
  }

  .iconfont {
    font-size: 28px;
    color: #9e9e9e;
  }

  .left {
    width: 20%;
  }

  .spaceName-content {
    width: 60%;
    text-align: left;
    padding-left: 12px;
    .layout {
      color: #666666;
    }
  }

  .deviceNum {
    color: #999;
    margin-top: 19px;
  }

  .ecllips {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
