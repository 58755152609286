<template>
  <div class="available-list">
    <img
      src="../../assets/img/logo_thumbnail.png"
      alt=""
      v-if="interFaceItem.status == 1"
      class="statusImg">
    <img
      :src="freshImg"
      alt=""
      v-if="interFaceItem.status == 2"
      :onerror="errorImgUrl"
      class="statusImg">
    <img
      src="../../assets/img/offlinePng.png"
      alt=""
      v-if="interFaceItem.status == 0"
      class="statusImg">
    <div class="device_name">
      <div
        class="left name_sub"
        :title="interFaceItem.peerId">{{interFaceItem.name}} </div>
      <div
        class="left name_sub colorR"
        :title="interFaceItem.livePeerId">
        <span v-if="interFaceItem.status == 2">&nbsp;&nbsp; ( {{getDeviceName(interFaceItem.livePeerName, splitSocketInfo&&splitSocketInfo.livePeerName||'')}} ) </span>
      </div>
    </div>

    <img
      src="../../assets/img/list-6.png"
      alt="drag"
      class="handle"
      v-if="!isManageWorkspaceCome">

    <div class="operateList">
      <el-dropdown
        placement="bottom-start"
        trigger="hover"
        @visible-change="queryWorkSpace">
        <img
          :src="list3"
          alt="" />
        <el-dropdown-menu
          slot="dropdown"
          class='add_btn'>
          <el-dropdown-item v-if="!currentIsWorkspace">
            <el-dropdown placement="start">
              <span>
                <span class="dupli icon iconfont ">&#xe6fe;</span> {{$t('lang.addTo')}}
                <i class="el-icon-caret-right"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                placement="start">
                <div class="access">
                  <el-dropdown-item
                    v-for="(val, key) in currentworkspaceList"
                    :key="key"
                    @click.native="copyWorkSpace(val)">
                    <span class="spaceName ellipsis">{{val.name}}</span>
                  </el-dropdown-item>
                </div>
                <el-dropdown-item
                  divided
                  @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item v-if="currentIsWorkspace">
            <el-dropdown placement="start">
              <span>
                <span class="dupli icon iconfont ">&#xe6b5; </span> {{$t('lang.moveTo')}}
                <i class="el-icon-caret-right"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                placement="start">
                <div class="access">
                  <el-dropdown-item
                    v-for="(val, key) in currentworkspaceList"
                    :key="key"
                    @click.native="moveWorkspace(val)">
                    <span class="spaceName ellipsis">{{val.name}}</span>
                  </el-dropdown-item>
                </div>
                <el-dropdown-item
                  divided
                  @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item v-if="currentIsWorkspace">
            <el-dropdown placement="start">
              <span>
                <span class="dupli icon iconfont ">&#xe6fe;</span> {{$t('lang.copyTo')}}
                <i class="el-icon-caret-right"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                placement="start">
                <div class="access">
                  <el-dropdown-item
                    v-for="(val, key) in currentworkspaceList"
                    :key="key"
                    @click.native="copyWorkSpace(val)">
                    <span class="spaceName ellipsis">{{val.name}}</span>
                  </el-dropdown-item>
                </div>
                <el-dropdown-item
                  divided
                  @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-dropdown-item>
          <el-dropdown-item
            v-if="currentIsWorkspace"
            @click.native="deleteDevice">
            <span>
              <span class="dupli icon iconfont ">&#xe63e;</span> {{$t('lang.remove')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
/*
 * Manage device的所有device列表组件
 * 和 Manage Workspace中的左侧的device列表
 */
import Bus from '@/assets/js/vueBus.js'
import toTips from '@/assets/js/toTipsObj'

export default {
  props: {
    interFaceItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentSpace: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: Boolean,
    isWorkspace: Boolean, // 是否是从workspace进来的 ,true显示拖拽按钮
    isManageWorkspaceCome: Boolean, // 是否是从manage进来的
    splitSocketInfo: Object
  },
  data () {
    return {
      blobService: this.$store.state.common.baseUrl.blobUrl,
      deafultImg: require('@/assets/img/logo_thumbnail.png'),
      freshImg: '', // 要被刷新的图片的路径
      errorImgUrl: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      currentAllworkspaceList: [],
      currentworkspaceList: [],
      list3: require('@/assets/img/list-3.png')
    }
  },
  components: {},
  created () {
    if (this.interFaceItem.status == 2) {
      if (this.interFaceItem.livePeerType == 'X') {
        this.freshImg = window.location.protocol + '//' + this.blobService + '/' + this.interFaceItem.livePeerId + '?random=' + Math.random()
      } else {
        this.freshImg = window.location.protocol + '//' + this.blobService + '/' + this.interFaceItem.peerId + '?random=' + Math.random()
      }
    } else {
      this.freshImg = this.deafultImg
    }
  },
  computed: {
    currentIsWorkspace () {
      return this.isWorkspace
    },
    currentSpaceInfo () {
      return this.currentSpace
    }
  },
  methods: {
    // device name
    getDeviceName (sptItemInfoName, socketDataName) {
      if (sptItemInfoName) {
        return sptItemInfoName
      }
      return socketDataName || ''
    },
    getMoveList () {
      this.axios.get('/ccp/tvugm/workspace/excludeWorkspace/' + this.interFaceItem.peerId || '').then(res => {
        if (res.data.errorCode == '0x0') {
          this.currentworkspaceList = res.data.result
        }
      }).catch(() => {})
    },
    queryWorkSpace (val) {
      if (val) {
        this.getMoveList()
      }
    },
    moveWorkspace (val) {
      const condition = {
        fromWorkspaceId: this.currentSpaceInfo.id,
        toWorkspaceId: val.id,
        devices: this.interFaceItem.peerId
      }
      this.axios.post('/ccp/tvugm/workspaceDevice/move', condition).then(res => {
        if (res.data.errorCode == '0x0') {
          this.$message({
            message: this.$t('lang.moveSuccess'),
            type: 'success'
          })
          Bus.$emit('regetDevice', {})// 获取移动后最新列表
          return
        }
        const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
      }).catch(() => {})
    },
    copyWorkSpace (val) {
      const condition = {
        workspaceId: val.id,
        devices: this.interFaceItem.peerId
      }
      this.axios.post('/ccp/tvugm/workspaceDevice/add', condition).then(res => {
        if (res.data.errorCode == '0x0') {
          if (this.currentIsWorkspace) {
            this.$message({
              message: this.$t('lang.copySuccess'),
              type: 'success'
            })
          } else {
            this.$message({
              message: this.$t('lang.addSuccess'),
              type: 'success'
            })
          }
          Bus.$emit('regetSpace')// 重新请求一次workspace 只请求一次 space 请求的数据传递到子组件内
          return
        }
        const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
      }).catch(() => {})
    },
    deleteDevice () {
      this.$confirm(this.$t('lang.deleteInfo'), '', {
        confirmButtonText: this.$t('lang.confirm'),
        cancelButtonText: this.$t('lang.cancel'),
        center: true,
        callback: action => {
          if (`${action}` == 'confirm') {
            const condition = {
              workspaceId: this.currentSpaceInfo.id,
              devices: this.interFaceItem.peerId
            }
            this.axios.post('/ccp/tvugm/workspaceDevice/delete', condition).then(res => {
              if (res.data.errorCode == '0x0') {
                this.$message({
                  message: this.$t('lang.deleteSuccess'),
                  type: 'success'
                })
                Bus.$emit('regetDevice', {})// 获取删除后最新列表
              }
            }).catch(() => {})
          }
        }
      }
      )
    },
    createWorkSpace () {
      Bus.$emit('addWorkspace', { type: 'createNew' })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dropdown-menu__item {
  line-height: 40px;
  width: 100%;
}
.el-dropdown-menu .el-dropdown-menu {
  width: 170px;
  left: 135px;
  top: -10px;
  .spaceName {
    width: 132px;
    display: block;
  }
}

.el-dropdown-menu .access {
  max-height: 150px;
  overflow-y: scroll;
}
.el-dropdown-menu {
  overflow: inherit;
  width: 135px;
  .el-dropdown-menu__item:focus {
    background: #444;
  }
  .el-dropdown-menu__item:focus:hover {
    background: rgba(57, 170, 80, 0.25);
  }
}
.el-dropdown-menu.add_btn > li {
  padding: 0;
  .el-dropdown {
    color: #fff;
    > span {
      display: inline-block;
      height: 100%;
      padding: 0 8px;
    }
  }
}
.available-list {
  width: 96%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  &:hover {
    box-shadow: 0 2px 5px #101010;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .statusImg {
    width: 100%;
    height: calc(100% - 30px);
    /*min-width: 238px;*/
  }
  .colorR {
    color: red;
  }
  .name_sub {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .device_name {
    padding: 0 10px;
    text-align: left;
    color: #ccc;
    font-size: 14px;
    background: #333431;
    line-height: 30px;
    height: 30px;
  }

  .handle {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
  }

  &:hover .handle {
    display: block;
  }

  .operateList {
    position: absolute;
    left: 8px;
    top: 10px;
    z-index: 10;
    display: none;
  }

  &:hover .operateList {
    display: block;
  }
}
</style>
