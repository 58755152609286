<template>
  <!--创建EXT跟grid配对的按钮-->
  <el-dialog
    :title="title"
    :visible.sync="data.show"
    width="620px"
    :center="true"
    :close-on-click-modal="false"
    class="add-source-dialog"
    @close="cancel">
    <div class="content scrollbar">
      <div class="item">
        <span class="iconfont">&#xeb33;</span>
        <el-input
          v-model.trim="spaceName"
          autocomplete="off"
          :placeholder="$t('lang.enternewWorkspace')"
          size="small"
          :maxlength="255"></el-input>
      </div>
      <div
        class="item"
        :class="{hide:operateType == 'reName'}">
        <span class="iconfont">&#xe660;</span>
        <el-select
          v-model="layout"
          placeholder="请选择">
          <el-option
            v-for="item in typeData"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <!--保存，取消按钮-->
      <el-button
        class="save"
        v-repeat-click="{event:save}">{{$t('lang.Save')}}</el-button>
      <el-button
        class="cancel"
        @click="cancel">{{$t('lang.cancel')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { multiviewPlusAnalyze } from '@/assets/js/googleAnalyze/multiviewPlus.js'
import toTips from '@/assets/js/toTipsObj'
export default {
  props: {
    data: Object,
    spaceInfo: { // 如果这个有信息说明是从编辑workspace过来的
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      typeData: [
        { value: '2x2', label: '2x2' },
        { value: '2x3', label: '2x3' },
        { value: '3x3', label: '3x3' },
        { value: '4x4', label: '4x4' },
        { value: '5x5', label: '5x5' }
      ],
      spaceName: '',
      layout: '2x2',
      operateType: 'createNew',
      title: this.$t('lang.newWorkspace')
    }
  },
  components: {},
  created () {
    this.operateType = this.spaceInfo.type
    this.spaceName = this.spaceInfo.name ? this.spaceInfo.name : ''
    this.layout = this.spaceInfo.layout ? this.spaceInfo.layout : '2x2'
    if (this.operateType == 'createNew') {
      this.title = this.$t('lang.newWorkspace')
    } else if (this.operateType == 'reName') {
      this.title = this.$t('lang.edit')
    } else if (this.operateType == 'duplicate') {
      this.title = this.$t('lang.duplicate')
    }
  },
  methods: {
    save () {
      if (!this.layout) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.plzSelectLayout')
        })
        return false
      }
      if (!this.spaceName) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.plzEnterspaceName')
        })
        return false
      }

      let url = ''
      let param = {}
      let event = ''
      if (this.operateType == 'createNew') {
        url = 'add'
        param.name = this.spaceName
        param.layout = this.layout
        event = 'Add'
      } else if (this.operateType == 'reName') {
        url = 'edit'
        param.id = this.spaceInfo.id
        param.name = this.spaceName
        event = 'Edit'
      } else if (this.operateType == 'duplicate') {
        url = 'duplicate'
        param.id = this.spaceInfo.id
        param.name = this.spaceName
        param.layout = this.layout
        event = 'Duplicate'
      }
      if (param.name.length > 255) {
        this.$message.error(`${this.$t('lang.nameLessThan')} 255`)
        return
      }
      multiviewPlusAnalyze(`mvpWorkspace${event}`, this.$route.path)
      this.axios.post(`/ccp/tvugm/workspace/${url}`, param).then(res => {
        if (res.data.errorCode == '0x0') {
          Bus.$emit('clearSearchCondi') // 清空搜索条件
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          Bus.$emit('regetSpace')// 重新请求一次workspace 只请求一次 space 请求的数据传递到子组件内
          this.cancel()
          return
        }
        const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
        this.cancel()
      }).catch(() => {
      })
    },
    clear () {
    },
    cancel () {
      this.clear()
      this.data.show = false
    }
  }

}
</script>
<style lang="less" scoped>
::v-deep .el-select {
  width: 86% !important;
  float: left;

  .el-input {
    width: 100% !important;
  }
}

.add-source-dialog {
  .scrollbar {
    overflow-x: hidden;
  }

  .content {
    text-align: left;
    font-size: 16px;
    max-height: 55vh;
    overflow-y: auto;

    .item {
      height: 46px;
      margin-bottom: 40px;
      clear: both;

      span {
        display: block;
        float: left;
        line-height: 46px;
        font-size: 30px;
        color: #606266;
        padding-right: 3%;
        margin-bottom: 5px;
      }
    }

    ::v-deep .el-input {
      width: 86%;
      float: left;
    }

    ::v-deep .el-input .el-input__inner {
      border: 1px solid #444;
      background: #444;
      height: 46px;
      line-height: 46px;

      &::-webkit-input-placeholder {
        color: #666;
      }
    }

    ::v-deep .select-box {
      width: 100%;

      .el-input__inner {
        border: 1px solid #444;
        background: #444;
        height: 46px;
        line-height: 46px;
      }
    }

    ::v-deep .el-select {
      width: 100%;

      .el-select__input {
        color: #fff;
      }
    }

    ::v-deep .selectRlist {
      width: 100%;
      height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
