<template>
  <div
    class="audio"
    ref="audioColorful"
    :style="{height:heightT ? heightT + 'px' : '150px'}">
    <div
      class="father"
      :style="{height:height + 'px'}">
      <div
        class="audioLeft active"
        :style="{height:getHeightLeft}"></div>
    </div>
    <div
      class="father"
      :style="{height:height + 'px'}">
      <div
        class="audioRight active"
        :style="{height:getHeightRight}"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rId: {
      type: String,
      default: ''
    },
    heightT: Number // 如果传过来数据 就使用此数据来作为声音条的高度
  },
  data () {
    return {
      everyPercent: 0,
      voiceInfo: {},
      height: 0,
      heightTSend: 0
    }
  },
  created () {
    this.$nextTick(() => {
      this.height = this.$refs.audioColorful.offsetHeight
      // console.log(this.height)
      this.everyPercent = this.height / 52
      // this.everyPersent = 1.923;
    })
  },
  computed: {
    getHeightRight () {
      return (this.height - this.everyPercent * (Number(this.voiceInfo.RightDb) + 40)) + 'px'
    },
    getHeightLeft () {
      return (this.height - this.everyPercent * (Number(this.voiceInfo.LeftDb) + 40)) + 'px'
    },
    listenVoiceData () {
      return this.$store.state.common.deviceListVolumeChartData
    }
  },
  watch: {
    listenVoiceData (val) {
      val.map((v, i) => {
        if (v.rid == this.rId) this.voiceInfo = JSON.parse(v.data)
      })
    },
    rId (newVal, oldVal) {
      if (newVal != oldVal) {
        this.voiceInfo = {}
      }
    },
    heightT (val) {
      this.heightTSend = val
      this.height = val
      this.everyPercent = this.heightTSend / 52
    }
  }
}
</script>
<style scoped>
.father {
  position: relative;
  width: 3px;
  height: 100%;
  float: left;
  background: linear-gradient(
    0deg,
    rgba(27, 141, 23, 1) 0%,
    rgba(255, 232, 3, 1) 53%,
    rgba(209, 30, 20, 1) 100%
  );
}

.father:first-child {
  margin-right: 2px;
}

.active {
  width: 100%;
  height: 100%;
  background: #666;
  transition: all 0.1s;
}
</style>
