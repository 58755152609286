<template>
  <div
    class="mv-receiverlist"
    ref="mvpreceiverlist"
    @mouseover="mouseEnter"
    @mouseout="mouseOut">
    <div
      class="borderL"
      :class="{'mv-receiverlistHover':splitItemInfo.isVideoDom}"
      @mouseenter="switchShowIcon(true)"
      @mouseleave="switchShowIcon(false)">
      <div
        class="bg-img"
        v-if="socketData.status != 2 && !socketData.sdi"
        :class="{'onlineBg':socketData.status == 1,'offlineBg':socketData.status == 0}"></div>
      <div
        class="control-status-module"
        v-if="splitItemInfo.isVideoDom">
        <div class="left">
          <div class="hide">{{splitItemInfo}}</div>
          <span
            v-if="!(socketData.status == 2&&socketData.livePeerType == 'X')"
            class="fontStyle"
            :class="[{'receiverOlineLive' :socketData.status == 2 || socketData.status == 1,
                      'receiverOffline' :socketData.status == 0},`split${currentSplitIndex}ExceedStyle`]"
            :title="getDeviceName(splitItemInfo.name, socketData.name)">
            {{getDeviceName(splitItemInfo.name, socketData.name)}}
          </span>
          <span
            v-show="!(socketData.status == 2&&socketData.livePeerType == 'X')"
            v-if="(splitItemInfo.livePeerName || socketData.name)&&socketData.status == 2"
            class="fontStyle disLeft"> : </span>
          <span
            :class="[{redColor:splitItemInfo.livePeerName || socketData.livePeerName},`split${currentSplitIndex}ExceedStyle`]"
            class="fontStyle"
            v-if="socketData.status == 2"
            :title="splitItemInfo.livePeerName ? splitItemInfo.livePeerName : ''">
            {{getDeviceName(splitItemInfo.livePeerName, socketData.livePeerName)}}
          </span>
        </div>
        <div
          class="f_right full_icon"
          v-show="socketData.status == 2 || (socketData.sdi ? stream : false)">
          <i
            class="el-icon-full-screen pointer"
            @click='enterFullScreen'></i>
        </div>
      </div>
      <div
        class="rtcVideoBox"
        :id='`video_${splitItemInfo.peerId}`'
        v-if="socketData.status == 2 || socketData.sdi"
        :title="splitItemInfo.peerId">
        <div
          class="rtcVideo"
          :class='{full_screen_model:fullScreenFlag}'
          @mousemove="switchShowIcon(true, 'mouseover')">
          <video-module
            ref="changeVoiceMv"
            :needShowMuteIcon="operateIcon"
            :clearSelectR="true"
            height="100%"
            width='100%'
            :useOldR="false"
            deviceType="R"
            :source="socketData"
            :rid="splitItemInfo.peerId"
            @hasStream='hasStream'></video-module>
          <div
            class="audioVo"
            v-if="socketData.status==2&&fullScreenFlag">
            <audio-colorful
              :rId="splitItemInfo.peerId"
              :heightT="fullAudioHeight"></audio-colorful>
          </div>
          <div
            class='full_screen_detail'
            title=''
            v-if='operateIcon&&fullScreenFlag'>
            <div class="left">
              <div class="hide">{{splitItemInfo}}</div>
              <span
                v-if="!(socketData.status == 2&&socketData.livePeerType == 'X')"
                class="fontStyle full_device_name pointer f_left"
                :class="[{'receiverOlineLive' :socketData.status == 2 || socketData.status == 1,
                          'receiverOffline' :socketData.status == 0}]"
                :title="getDeviceName(splitItemInfo.name, socketData.name)">
                {{getDeviceName(splitItemInfo.name, socketData.name)}}
              </span>
              <span
                v-show="!(socketData.status == 2&&socketData.livePeerType == 'X')"
                v-if="(splitItemInfo.livePeerName || socketData.name)&&socketData.status == 2"
                class="fontStyle disLeft f_left"> : </span>
              <span
                :class="[{redColor:splitItemInfo.livePeerName || socketData.livePeerName}]"
                class="fontStyle full_device_name pointer"
                v-if="socketData.status == 2"
                :title="splitItemInfo.livePeerName ? splitItemInfo.livePeerName : ''">
                {{getDeviceName(splitItemInfo.livePeerName, socketData.livePeerName)}}
              </span>
            </div>
            <i
              class="iconfont pointer"
              @click='exitFullscreen'>&#xe6e1;</i>
          </div>
        </div>
      </div>
      <div
        class="mataData mvp-popover"
        v-if="socketData.status  == 2 && showInfoOpen">
        <el-popover
          placement="bottom"
          :width="getWidth"
          trigger="hover"
          :visible-arrow="true">
          <div v-html="splitItemInfo.description"></div>
          <span
            slot="reference"
            class="mataDataCursor">{{splitItemInfo.description}}</span>
        </el-popover>
      </div>
    </div>

    <img
      src="../../assets/img/list-6.png"
      alt="drag"
      class="handle"
      v-if="currentIsWorkspace && splitItemInfo.isVideoDom && !sort"
      :title="$t('lang.dragAndDrop')">

    <div
      class="audioVo"
      v-if="socketData.status == 2">
      <audio-colorful
        :rId="splitItemInfo.peerId"
        :heightT="heightT"></audio-colorful>
    </div>
    <div
      :class="['operateList',splitItemInfo.peerId]"
      v-if="splitItemInfo.isVideoDom">
      <el-dropdown
        placement="bottom-start"
        trigger="hover"
        @visible-change="queryWorkSpace"
        :tabindex="0">
        <img
          :src="list3"
          alt="list3" />
        <el-dropdown-menu
          slot="dropdown"
          :class="[currentIsWorkspace?'111':'addClass','operationList',currentIsLastOne?'lastOne':'']">
          <div @mouseover="mouseEnter">
            <el-dropdown-item v-if="!currentIsWorkspace">
              <el-dropdown
                :tabindex="1"
                placement="start"
                trigger="hover">
                <span>
                  <span
                    class="dupli icon iconfont"
                    style="float: left">&#xe6fe;</span> {{$t('lang.addTo')}}
                  <i
                    class="el-icon-caret-right"
                    style="float: right;line-height: 40px"></i>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  placement="start">
                  <div class="access">
                    <el-dropdown-item
                      v-for="(val, key) in currentworkspaceList"
                      :key="key"
                      @click.native="copyWorkSpace(val)">
                      <span class="spaceName ellipsis">{{val.name}}</span>
                    </el-dropdown-item>
                  </div>
                  <el-dropdown-item
                    divided
                    @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item v-if="currentIsWorkspace">
              <el-dropdown
                :tabindex="2"
                placement="start"
                trigger="hover">
                <span>
                  <span class="dupli icon iconfont ">&#xe6b5;</span> {{$t('lang.moveTo')}}
                  <i class="el-icon-caret-right"></i>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  placement="start">
                  <div class="access">
                    <el-dropdown-item
                      v-for="(val, key) in currentworkspaceList"
                      :key="key"
                      @click.native="moveWorkspace(val)">
                      <span class="spaceName ellipsis">{{val.name}}</span>
                    </el-dropdown-item>
                  </div>
                  <el-dropdown-item
                    divided
                    @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item v-if="currentIsWorkspace">
              <el-dropdown
                :tabindex="3"
                placement="start"
                trigger="hover">
                <span>
                  <span class="dupli icon iconfont ">&#xe6fe;</span> {{$t('lang.copyTo')}}
                  <i class="el-icon-caret-right"></i>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  placement="start">
                  <div class="access">
                    <el-dropdown-item
                      v-for="(val, key) in currentworkspaceList"
                      :key="key"
                      @click.native="copyWorkSpace(val)">
                      <span class="spaceName ellipsis">{{val.name}}</span>
                    </el-dropdown-item>
                  </div>
                  <el-dropdown-item
                    divided
                    @click.native="createWorkSpace">{{$t('lang.newWorkSpace')}}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="currentIsWorkspace"
              @click.native="deleteDevice">
              <span>
                <span class="dupli icon iconfont "> &#xe63e;</span> {{$t('lang.remove')}}
              </span>
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
/*
 * 每个分屏的组件 => 每个有视频的组件,和Manage device中的不一样(无视频 只是图片)
 *
 */
import videoModule from '@/components/VideoC'
import AudioColorful from '@/components/AudioColorful'
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import toTips from '@/assets/js/toTipsObj'

export default {
  props: {
    splitItemInfo: { // 该分屏信息
      type: Object,
      default: () => {
        return {}
      }
    },
    splitSocketInfo: { // 当前websocket中R信息
      type: Object,
      default: () => {
        return {}
      }
    },
    splitIndex: Number,
    showInfoRe: Boolean, // 控制条显示或隐藏的控制
    isFull: Boolean, // 是否点击了隐藏头部的按钮
    mataData: Array,
    isWorkspace: Boolean, // 是否是workspace
    sort: Boolean, // 是否是sort排序,如果是 不能拖拽排序
    allworkspaceList: Array,
    currentSpace: Object,
    isLastOne: Boolean// 控制条显示或隐藏的控制
  },
  components: {
    videoModule,
    AudioColorful
  },
  data () {
    return {
      receiverInfo: this.videoInfo,
      audioShow: false, // 声音控制
      hasVideo: false, // 是否有视频
      socketData: this.splitItemInfo, // websocket中的数据
      isMoreLive: false, // 是否显示多个同时在live的错误提示
      showInfoOpen: this.showInfoRe ? this.showInfoRe : false, // show info控制的control module
      showInfoOpen1: false,
      currentSplitIndex: this.splitIndex, // 当前分屏信息
      rvfOn: '', // 当前分屏信息
      currentIsFull: this.isFull,
      versionJuge: '',
      isHaveVideo: false, // 是否渲染出来视频
      metaDataC: '',
      currentIsWorkspace: this.isWorkspace, // 当前是否是workspace的引用
      currentworkspaceList: [], // 当前workspace列表
      list3: require('@/assets/img/list-3.png'),
      heightT: 150,
      fullAudioHeight: Math.floor(document.body.offsetHeight * 0.9),
      currentIsLastOne: this.isLastOne,
      isShowMenu: false,
      operateIcon: false, // 是否显示全屏和声音icon
      fullScreenFlag: false, // 是否是全屏模式
      timer: null,
      getWidth: null,
      stream: false
    }
  },
  created () {
    if (this.splitItemInfo.version) {
      this.rvfOn = this.splitItemInfo.version.slice(0, 3) >= 6.5 ? true : this.splitItemInfo.rvfPackOn
      if (this.splitItemInfo.version.slice(0, 3) == 6.1) {
        this.rvfOn = true
      }
    }
    Bus.$on('controlModuleChange', (val) => { // 监听showInfo信息
      this.$nextTick(() => {
        if (!val) { // control Module是打开的状态
          this.showInfoOpen = true
        } else {
          this.showInfoOpen = false
        }
      })
    })
    const that = this
    this.$nextTick(() => {
      this.handleResize()
      Bus.$emit('controlModuleChangeVideoC', false) // 关闭controls
    })

    window.addEventListener('resize', () => { // 监听页面全屏变化 做相应处理
      this.fullAudioHeight = Math.floor(document.body.offsetHeight * 0.9)
      if (!that.isFullScreen() && that.currentIsFull) {
        $('#pro_nav_icon_bg').hide()
      }
      if (that.$refs.changeVoiceMv && that.$refs.changeVoiceMv.returnVideoObj()) {
        const videoObj = that.$refs.changeVoiceMv.returnVideoObj()
        if (videoObj && videoObj.obj) {
          if (videoObj.obj.muted) { // 声音来回切换 保证声音按钮状态一致
            that.audioShow = false
          } else {
            that.audioShow = true
          }
        }
      }
      that.handleResize()
    })

    this.$nextTick(() => {
      this.heightT = $('.left.splitBorder').width() * 212 / 363 - 112
      this.heightT = this.heightT < 70 ? 70 : this.heightT
    })
  },
  mounted () {
    document.addEventListener('fullscreenchange', () => {
      if (!this.checkFull()) {
        this.fullScreenFlag = false
      }
    }, false)
    document.addEventListener('mozfullscreenchange', () => {
      if (!this.checkFull()) {
        this.fullScreenFlag = false
      }
    }, false)
    document.addEventListener('MSFullscreenChange', () => {
      if (!this.checkFull()) {
        this.fullScreenFlag = false
      }
    }, false)
    document.addEventListener('webkitfullscreenchange', () => {
      if (!this.checkFull()) {
        this.fullScreenFlag = false
      }
    }, false)
  },
  computed: {
    ...mapState('common', {
      States: state => state
    }),
    currentAllworkspaceList () {
      return this.allworkspaceList
    },
    currentSpaceInfo () {
      return this.currentSpace
    }
  },
  methods: {
    hasStream (val) { // 是否有流
      this.stream = val
    },
    // device name
    getDeviceName (sptItemInfoName, socketDataName) {
      if (sptItemInfoName) {
        return sptItemInfoName
      }
      return socketDataName || ''
    },
    // 是否是全屏
    checkFull () {
      let isFull =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement
      if (isFull === undefined) isFull = false
      return isFull
    },
    // 进入全屏模式
    enterFullScreen () {
      const ele = document.querySelector(`#video_${this.splitItemInfo.peerId}`)
      if (!ele) return
      if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen()
        this.fullScreenFlag = TextTrackCue
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
        this.fullScreenFlag = true
      } else if (ele.requestFullscreen) {
        ele.requestFullscreen()
        this.fullScreenFlag = true
      }
    },
    // 退出全屏模式
    exitFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
        this.fullScreenFlag = false
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
        this.fullScreenFlag = false
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
        this.fullScreenFlag = false
      }
    },
    // 显示或隐藏声音和全屏icon
    switchShowIcon (val, mousemove) { // fullScreen
      if (mousemove && !this.fullScreenFlag) return // 不是全屏时，鼠标移动 return
      this.operateIcon = val
      if (this.fullScreenFlag) {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.fullScreenFlag) this.operateIcon = false
        }, 5000)
      }
    },
    mouseOut () {
      $('.operateList').css('display', 'none')
    },
    mouseEnter () {
      $('.operateList').css('display', 'none')
      $('.operateList.' + this.splitItemInfo.peerId).css('display', 'inline-block')
    },
    handleResize () {
      const splitBorder = $('.left.splitBorder').width()
      $('.left.splitBorder').css('height', splitBorder * 212 / 363)
      this.heightT = splitBorder * 212 / 363 - 112
      this.heightT = this.heightT < 70 ? 70 : this.heightT
      this.getWidth = $('.mv-receiverlist').width()
    },
    isFullScreen () { // 返回是否全屏
      return !!(
        document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      )
    },
    getMoveList () {
      this.axios.get('/ccp/tvugm/workspace/excludeWorkspace/' + this.splitItemInfo.peerId || '').then(res => {
        if (res.data.errorCode == '0x0') {
          this.currentworkspaceList = res.data.result
        }
      }).catch(() => {})
    },
    queryWorkSpace (val) {
      this.isShowMenu = true
      if (val) {
        this.getMoveList()
      }
    },
    moveWorkspace (val) {
      const condition = {
        fromWorkspaceId: this.currentSpaceInfo.id,
        toWorkspaceId: val.id,
        devices: this.splitItemInfo.peerId
      }
      this.axios.post('/ccp/tvugm/workspaceDevice/move', condition).then(res => {
        if (res.data.errorCode == '0x0') {
          if (this.isWorkspace) {
            Bus.$emit('regetSpaceDevice', this.splitItemInfo)// 获取移动后最新列表
          }
          this.$message({
            message: this.$t('lang.moveSuccess'),
            type: 'success'
          })
          return
        }
        const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
      }).catch(() => {})
    },
    copyWorkSpace (val) {
      const condition = {
        workspaceId: val.id,
        devices: this.splitItemInfo.peerId
      }
      this.axios.post('/ccp/tvugm/workspaceDevice/add', condition).then(res => {
        if (res.data.errorCode == '0x0') {
          if (this.isWorkspace) {
            this.$message({
              message: this.$t('lang.copySuccess'),
              type: 'success'
            })
          } else {
            this.$message({
              message: this.$t('lang.addSuccess'),
              type: 'success'
            })
          }
          return
        }
        const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
        this.$message.error(errorInfo)
      }).catch(() => {})
    },
    deleteDevice () {
      this.$confirm(this.$t('lang.deleteInfo'), '', {
        confirmButtonText: this.$t('lang.confirm'),
        cancelButtonText: this.$t('lang.cancel'),
        center: true,
        callback: action => {
          if (`${action}` == 'confirm') {
            const condition = {
              workspaceId: this.currentSpaceInfo.id,
              devices: this.splitItemInfo.peerId
            }
            this.axios.post('/ccp/tvugm/workspaceDevice/delete', condition).then(res => {
              if (res.data.errorCode == '0x0') {
                this.$message({
                  message: this.$t('lang.deleteSuccess'),
                  type: 'success'
                })
                if (this.isWorkspace) {
                  // 如果在workspace列表内, 删除后更新device列表
                  Bus.$emit('regetSpaceDevice', this.splitItemInfo)// 获取删除后最新列表
                }
              }
            }).catch(() => {})
          }
        }
      }
      )
    },
    createWorkSpace () {
      Bus.$emit('addWorkspace', { type: 'createNew' })
    }

  },
  watch: {
    splitSocketInfo (val) {
      this.socketData = val
      this.States.deviceListCurrentRInfoData.map((v, i) => {
        if (v.rid == this.splitItemInfo.peerId) {
          if (v.data) {
            const currentRInfo = JSON.parse(v.data)
            if (this.splitItemInfo.version) {
              this.rvfOn = this.splitItemInfo.version.slice(0, 3) >= 6.5 ? true : currentRInfo.RVFPackOn
              if (this.splitItemInfo.version.slice(0, 3) == 6.1) {
                if (currentRInfo.WebRVideoSolution === 300) {
                  this.rvfOn = true
                } else {
                  this.rvfOn = currentRInfo.RVFPackOn
                }
              }
            }
          }
        }
      })

      this.$nextTick(() => {
        if (this.$refs.changeVoiceMv && this.$refs.changeVoiceMv.returnVideoObj) {
          const videoObj = this.$refs.changeVoiceMv.returnVideoObj() // 获取当前video对象
          if (videoObj.obj && videoObj.obj.parentNode && videoObj.obj.parentNode.style.display != 'none') {
            this.isHaveVideo = true
          } else {
            this.isHaveVideo = false
          }
        }
      })
    },
    splitIndex (val) { // 分屏信息监听
      this.currentSplitIndex = val
      this.$nextTick(() => {
        this.heightT = $('.left.splitBorder').width() * 212 / 363 - 112
        this.heightT = this.heightT < 70 ? 70 : this.heightT
        this.getWidth = $('.mv-receiverlist').width()
      })
    },
    isFull (val) {
      this.currentIsFull = val
    },
    isWorkspace (val) {
      this.currentIsWorkspace = val
    }

  }

}
</script>
<style lang="less" scoped>
@media (max-width: 1288px) {
  .lastOne.el-dropdown-menu .el-dropdown-menu {
    left: -150px !important;
  }
}
.mv-receiverlist:hover .full_icon {
  display: block;
}
.mv-receiverlist:hover .handle {
  cursor: pointer;
  display: block;
}
.mv-receiverlistHover:hover {
  box-shadow: 0 2px 5px #101010;
}

::v-deep .el-dropdown-menu__item {
  line-height: 40px;
  width: 100%;
}

.el-popper .popper__arrow {
  top: -6px;
  left: 50%;
  margin-right: 3px;
  border-top-width: 0;
  border-bottom-color: #333431;
}

.el-popper {
  margin: 11px 0 0 0 !important;
  width: 138px;
}
.el-dropdown-menu.operationList > div > li {
  padding: 0;
  .el-dropdown {
    color: #fff;
    > span {
      display: inline-block;
      height: 100%;
      padding: 0 8px;
    }
  }
}

.el-dropdown-menu .el-dropdown-menu {
  width: 140px;
  left: 116px;
  top: -22px;

  .spaceName {
    width: 125px;
    display: block;
  }
}

.el-dropdown-menu .access {
  max-height: 140px;
  overflow-y: scroll;
}

.el-dropdown-menu {
  overflow: inherit;
  width: 115px;
  .el-dropdown-menu__item {
    padding: 0 8px;
  }
  .el-dropdown-menu__item:focus {
    background: #444;
  }
  .el-dropdown-menu__item:focus:hover {
    background: rgba(57, 170, 80, 0.25);
  }
}

.addClass.el-dropdown-menu {
  width: 102px;
  .el-dropdown-menu {
    left: 101px;
  }
}

.mv-receiverlist {
  width: 100%;
  height: 100%;
  position: relative;
  /*background: #444;*/

  .audioVo {
    position: absolute;
    top: 40px;
    right: 13px;
    z-index: 11;
  }

  .handle {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 15;
    display: none;
  }

  .operateList {
    position: absolute;
    left: 5px;
    top: 10px;
    z-index: 15;
    display: none;
  }

  &:hover .operateList {
    display: block;
  }

  .borderL {
    width: 100%;
    height: 100%;
  }

  .errorMsg {
    position: absolute;
    top: 50%;
    font-size: 18px;
    font-weight: bolder;
    color: red;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
  }

  ::v-deep .mataData {
    position: absolute;
    // bottom: 4.5%;
    bottom: 27px;
    font-size: 18px;
    font-weight: bolder;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
    z-index: 10;

    .el-popover__reference {
      width: 100%;
      display: inline-block;
      font-size: 0.16rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mataDataCursor {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .errorMsgMoreLive {
    position: absolute;
    top: 24%;
    font-size: 18px;
    font-weight: bolder;
    color: #ff9000;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
  }

  .borderClass {
    width: 100%;
    height: 100%;
    /*background: #333;*/
    box-sizing: border-box;
    border: 1px dashed #444;
  }

  .closeIconBtn {
    width: 34px;
    height: 37px;
    position: absolute;
    right: 14px;
    top: 0;
    cursor: pointer;
    z-index: 1;

    .closeIconMv {
      font-size: 0.4rem;
      z-index: 1;
    }
  }

  .bg-img {
    width: 100%;
    height: 100%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .onlineBg {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/logo.png);
    background-size: 15%;
    background-repeat: no-repeat;
    background-color: #2f3330;
    background-position: 50% 40%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .offlineBg {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/MVOffline.png);
    background-size: 15%;
    background-repeat: no-repeat;
    background-color: #2f3330;
    background-position: 50% 45%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .control-status-module {
    width: 100%;
    height: 38px;
    line-height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 10px;
    box-sizing: border-box;
    /*border: 1px solid #666;*/
    /*background-color: rgba(0, 0, 0, 0.6);*/
    background-color: rgba(37, 37, 37, 0.6);
    z-index: 15;

    .disLeft {
      display: inline-block;
      float: left;
      padding: 0 2px;
    }

    .iconfont {
      font-size: 0.24rem;
      padding: 0 0.06rem;
    }

    .receiverOlineLive {
      color: #33ab4f;
    }

    .receiverOffline {
      color: #999;
    }

    .fontStyle {
      font-weight: bold;
    }

    span {
      cursor: pointer;
    }
  }
  .full_icon {
    display: none;
  }
  .el-icon-full-screen {
    font-size: 15px;
  }

  .rtcVideoBox {
    width: 100%;
    height: 100%;
    .rtcVideo.full_screen_model {
      ::v-deep .muteIcon,
      ::v-deep .noMuteIcon {
        z-index: 510;
        right: 50px;
        bottom: 5px;
      }
      ::v-deep .muteIcon:before,
      ::v-deep .noMuteIcon:before {
        color: #dbdbdb;
        font-size: 18px;
      }
    }

    .rtcVideo {
      width: 100%;
      height: 100%;
      position: relative;

      .full_screen_detail {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        background: rgba(0, 0, 0, 0.7);
        z-index: 509;
        .full_device_name {
          max-width: 300px;
        }
        i {
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .full_screen_title {
        height: 36px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 11px 0 0 16px;
        z-index: 510;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.72) 100%
        );
      }

      .url {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: orange;
        width: 90%;
      }

      ::v-deep .muteIcon,
      ::v-deep .noMuteIcon {
        z-index: 510;
        right: 39px;
        bottom: 10px;
      }
      ::v-deep .muteIcon:before,
      ::v-deep .noMuteIcon:before {
        color: #dbdbdb;
        font-size: 18px;
      }

      .receiverInfo {
        position: absolute;
        top: 16px;
        z-index: 5;
        width: 100%;

        .text {
          margin-left: 16px;
          width: 52px;
          height: 24px;
          background: rgba(255, 51, 51, 1);
          border-radius: 2px;
          opacity: 0.76;
          text-align: center;
          line-height: 24px;
        }

        .info {
          font-size: 14px;
          font-weight: bold;
          margin-right: 12px;
        }
      }
    }

    .operationBox {
      padding: 10px 8px;
      background: #08130a;

      .dots {
        background: #33aa50;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: inline-block;
        float: left;
        margin-top: 6px;
        margin-right: 5px;
      }

      .hideDot {
        visibility: hidden;
      }

      .TSyncStatus {
        margin-top: 10px;
      }

      .operationBox-top {
        height: 18px;

        .options {
          font-size: 16px;

          .fullScreenIcon:before {
            content: '\e6a3';
            margin-right: 12px;
          }

          .mutedIcon:before {
            content: '\e615';
            margin-right: 12px;
            color: #ff3333;
          }

          .infoIcon:before {
            content: '\e6a0';
          }

          .fullScreenIcon.disabled {
            cursor: not-allowed;
            color: gray;
          }

          .noMutedIcon:before {
            content: '\e613';
            margin-right: 12px;
            color: #fff;
          }
        }
      }

      .operationBox-body {
        height: 32px;
        margin-top: 5px;

        .selectBox {
          width: 185px;
        }
      }
    }
  }
}

.mv-receiverlist:hover .closeIconBtn {
  display: block;
  z-index: 99;
}

.redColor {
  color: red;
}

.split25ExceedStyle {
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split16ExceedStyle {
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split9ExceedStyle {
  max-width: 148px;
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split6ExceedStyle {
  max-width: 153px;
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split4ExceedStyle {
  max-width: 240px;
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
@media screen and (min-width: 1200px) and (max-width: 1485px) {
  .mv-receiverlist .control-status-module {
    height: 22px;
    line-height: 22px;
  }
  .mv-receiverlist .rtcVideoBox .rtcVideo {
    ::v-deep .muteIcon,
    ::v-deep .noMuteIcon {
      bottom: 2px;
    }
    ::v-deep .muteIcon:before,
    ::v-deep .noMuteIcon:before {
      font-size: 15px;
    }
  }
  .mv-receiverlist .el-icon-full-screen {
    font-size: 13px;
  }
  .mv-receiverlist .mataData {
    bottom: 12px;
  }
}
@media screen and (min-width: 500px) and (max-width: 1285px) {
  .mv-receiverlist .audioVo {
    top: 40%;
    transform: translateY(-50%);
  }
  .mv-receiverlist .control-status-module {
    height: 15px;
    line-height: 15px;
  }
  .mv-receiverlist .el-icon-full-screen {
    font-size: 12px;
  }
  .mv-receiverlist .rtcVideoBox .rtcVideo {
    ::v-deep .muteIcon,
    ::v-deep .noMuteIcon {
      bottom: 0;
    }
    ::v-deep .muteIcon:before,
    ::v-deep .noMuteIcon:before {
      font-size: 12px;
    }
  }
  .mv-receiverlist .mataData {
    bottom: 5px;
  }
}
</style>

<style lang="less">
.operationList.el-dropdown-menu {
  .el-dropdown-menu__item.el-dropdown-menu__item--divided::before {
    margin: 0 -8px;
  }
}
</style>
