<template>
  <div class="mv-content">
    <div
      v-if="!manageDeviceAndSpace"
      class="clearfix"
      style='height:100%;'>
      <div
        class="bk-voip-select"
        v-if="!showFullScreen">
        <!--头部-->
        <div class="left spaceName">
          {{$t('lang.workspace')}}
        </div>
        <div class="left selectDown">
          <el-select
            class="select pawan"
            placeholder="Select"
            v-model="workspaceListCurrent"
            @change="changeRouter"
            @visible-change="showHideVi"
            value-key="id">
            <el-option
              class="default"
              :key="'default'"
              :label="$t('lang.allDevice')"
              :value="'default'"
              :title="$t('lang.allDevice')"></el-option>
            <div style="height: 160px;overflow-x: hidden;overflow-y: scroll">
              <el-option
                v-for="item in workspaceList"
                :key="item.id"
                :label="item.name"
                :value="item"
                :title="item.name"></el-option>
            </div>
            <el-option
              :key="'manageWorkspace'"
              :label="$t('lang.ManageWorkspace')"
              :value="'manageWorkspace'"
              class="bottom"
              :title="$t('lang.ManageWorkspace')"></el-option>
          </el-select>
        </div>
        <div
          class="left searchDevice"
          v-if="!isWorkspace">
          <search-input
            :placeholder="$t('lang.searchTText')"
            :callback="search"
            :value="filter.condition"></search-input>
        </div>
        <div
          class="left searchDevice"
          v-if="isWorkspace">
          <search-input
            :placeholder="$t('lang.searchTText')"
            :callback="searchSpaceDevice"
            :value="filterspaceDevice.condition"></search-input>
        </div>

        <div class="left statusDevice">
          <div
            class="statusBox left"
            v-show='isShow.filterItem'>
            <span class="statusName left">{{$t('lang.status')}}:</span>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              class="allStatus left">
              {{$t('lang.all')}}
            </el-checkbox>
            <el-checkbox-group
              v-model="checkedCities"
              @change="handleCheckedCitiesChange"
              class="statusCheckBox">
              <el-checkbox
                v-for="filter in filterOptionObj"
                :label="filter"
                class="statusCheck"
                :class="{
                  red:filter == 'live',
                  green:filter == 'online',
                  lightGrey:filter == 'offline',
                }"
                :key="filter">{{$t(`lang.${filter}`)}}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div
          class="left filter_setting"
          v-if="isWorkspace">
          <img
            src="../../assets/img/manage.png"
            alt
            @click="manageGridSources" />
          <span @click="manageGridSources">{{$t('lang.ManageDevices')}}</span>
        </div>
        <div
          class="left paginationDevice"
          v-if="!isWorkspace">
          <Pagination
            @current-change="handleCurrentChange"
            :current-page="filter.pageNum"
            :pageSizes="[4, 6, 9, 12, 16, 25]"
            :pageSize="filter.pageSize"
            :total="total"
            @size-change="handleSizeChange"
            layout="prev, pageF, next, pageL, nowSize, jumper">
          </Pagination>
        </div>
        <div
          class="right btn_green"
          v-if="isShowGoToMultiview">
          <a
            href="javascript:void(0)"
            :title="$t('lang.tomultiview')"
            @click="goToMultiview">
            {{$t('lang.tomultiview')}} </a>
        </div>

        <div class="splitScreen">
          <div class="showInfo">
            {{$t('lang.showDesc')}}
            <el-switch
              v-model="showInfo"
              active-color="#13ce66"
              @change="changeShowInfo()">
            </el-switch>
          </div>

          <div
            class="sortName"
            v-if='isWorkspace'
            :title="$t('lang.mvpRecevierSort')">
            <i
              class="iconfont"
              :class="{active:sortIsActive}"
              @click="sortChange">&#xe7a7;</i>
          </div>
          <div
            class="splitScreenNum"
            :class="{splitScreenNumAc:isWorkspace}">
            <!--分屏数量选择-->
            <i
              class="iconfont split4"
              :class="{'splitActive':splitIndex == 4,'isAllow':isWorkspace}"
              @click="changeSplit(4)">&#xe684;</i>

            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 6,'isAllow':isWorkspace}"
              @click="changeSplit(6)">&#xe631;</i>

            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 9,'isAllow':isWorkspace}"
              @click="changeSplit(9)">&#xe62f;</i>

            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 16,'isAllow':isWorkspace}"
              @click="changeSplit(16)">&#xe632;</i>

            <i
              class="iconfont"
              :class="{'splitActive':splitIndex == 25,'isAllow':isWorkspace}"
              @click="changeSplit(25)">&#xe633;</i>

            <i
              class="iconfont fullScreen splitActive"
              @click="fullScreen(true)">&#xe658;</i>
          </div>
        </div>

      </div>

      <i
        class="iconfont fullScreen splitActive fullClose"
        @click="fullScreen(false)"
        v-if="showFullScreen">&#xe659;</i>

      <div
        class="receiverLists"
        :class="{'view4-padding':splitIndex ==4, 'view6-padding':splitIndex ==6,'view9-padding':splitIndex ==9,'view16-padding':splitIndex ==16,'view25-padding':splitIndex ==25,'isfull':!showFullScreen}"
        v-if="isInit"
        v-loading='loading'
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <draggable
          class=""
          v-model="splitDataArr"
          handle=".handle"
          @change="changeHandleHomeSort">
          <div
            class="left splitBorder"
            v-for="(item,index) in splitDataArr"
            :class="{'videosize-4':splitIndex ==4, 'videosize-6':splitIndex ==6,'videosize-9':splitIndex ==9,'videosize-16':splitIndex ==16,'videosize-25':splitIndex ==25}"
            :key="item.peerId">
            <receiver-list
              :splitItemInfo="item"
              :splitSocketInfo="allRSocketInfo[item.peerId]"
              :splitIndex="splitIndex"
              :sendSplitIndex="splitIndex"
              :showInfoRe="showInfo"
              :isFull="showFullScreen"
              :mataData="mataData"
              :isWorkspace="isWorkspace"
              :sort="sortIsActive"
              :allworkspaceList="workspaceList"
              :currentSpace="currentSpaceInfo"
              :isLastOne="(index+1)%Math.sqrt(splitIndex)==0"></receiver-list>
          </div>
        </draggable>
      </div>
    </div>
    <ManageDeviceAndSpace
      v-if="manageDeviceAndSpace"
      @hideManage="hideManage"
      :allRSocketInfo='allRSocketInfo'
      :currentSpaceInfo="currentSpaceInfo"
      :isManageWorkspace="isManageWorkspace"
      :workspaceList="workspaceList"
      :loadingSpaceList='loadingSpaceList'></ManageDeviceAndSpace>
    <add-workspace
      v-if="add.show"
      :data="add"
      :spaceInfo="spaceInfo"></add-workspace>
  </div>
</template>
<script>
// 主页面,本组件 主要是 multiview的升级版, 目前是通过 路由跳转的方式在老的multiview上进行来回跳转
import { mapState, mapMutations } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import ReceiverList from './ReceiverList'
import ManageDeviceAndSpace from './ManageDeviceAndSpace'
import AddWorkspace from './AddWorkspace'
import { multiviewPlusAnalyze } from '@/assets/js/googleAnalyze/multiviewPlus.js'
import SearchInput from '@/components/Search'
import Pagination from '@/components/Pagination'
import draggable from 'vuedraggable'
const cityOptions = ['live', 'online', 'offline']

export default {
  components: {
    ReceiverList,
    SearchInput,
    Pagination,
    ManageDeviceAndSpace,
    draggable,
    AddWorkspace
  },
  props: {},
  data () {
    return {
      showInfo: true, // showInfo开关
      splitIndex: 25,
      splitDataArr: [], // R列表
      peerIds: [], // 分屏Rid
      initData: [], // 初始化数据
      allRSocketInfo: [], // 组装后的所有websocket中的数据
      selectVoipDevice: false, // 控制选择设备进行通话
      selectDeviceData: [], // 从selectDevice组件获取的数据
      rvfPackOnArr: [], // 控制errorMsg的显示或隐藏
      showFullScreen: false, // 是否显示全屏
      bookmarkChecked: [], // 当前选中的R的id列表
      canVoipPlay: false, // voip按钮是否可以点击
      isInit: true, // 是否初始化完成
      initCurrentInfoSocket: true,
      isClick: false, // 是否点击了下拉
      mataData: [], // mataData信息
      workspaceList: [],
      currentSpaceInfo: {},
      loadingSpaceList: false,
      workspaceListCurrent: 'default',
      filter: { // 所有设置搜索时的参数
        live: true,
        online: true,
        offline: true,
        condition: '',
        pageNum: 1,
        pageSize: 25
      },
      filterspaceDevice: { // 搜索space下面的设备参数
        condition: '',
        id: '',
        live: true,
        online: true,
        offline: true,
        sort: 'name'
      },
      filterWorkspace: { // 获取space列表参数
        condition: '',
        pageNum: 1,
        pageSize: 10000
      },
      total: 0,
      isShow: {
        filterItem: true
      },
      checkAll: true, //
      checkedCities: ['live', 'online', 'offline'],
      cities: cityOptions,
      isIndeterminate: false,
      filterOptionObj: ['live', 'online', 'offline'],
      sort: true,
      loading: false,
      isWorkspace: false, // receiver列表是否是workspace
      manageDeviceAndSpace: false, // 是否显示manage Device
      isManageWorkspace: false,
      add: { // add workspace
        show: false
      },
      spaceInfo: {},
      isShowGoToMultiview: this.$store.state.common.baseUrl.enableNewMultiView,
      sortIsActive: true,
      isLastOne: false,
      ajaxR: [], // 版本小于7.5的R id
      onlineSDIPeerId: [],
      lowVersionOnlineR: []
    }
  },
  created () {
    this.getDevice()
    this.getWorkspace()
    // 点击别处--下拉列表消失
    Bus.$on('workspaceChangeSpace', (item) => {
      // 监听从manageWorkspace中来的切换space的操作
      this.workspaceListCurrent = item
      this.manageDeviceAndSpace = !this.manageDeviceAndSpace
      this.isManageWorkspace = !this.isManageWorkspace
      this.changeRouter(item)
    })
    Bus.$on('addWorkspace', (item) => {
      // 监听添加space弹出弹窗的操作
      this.add.show = true
      this.spaceInfo = item
    })

    Bus.$on('regetSpace', (item) => {
      // 监听添加space,删除space,复制space,编辑space时重新请求space的操作的操作
      this.getWorkspace()
    })

    Bus.$on('regetSpaceDevice', (item) => {
      // 监听添加space,删除space,复制space,查询设备的操作
      this.getWorkspaceDevice()
    })
  },
  computed: {
    ...mapState({
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    }),
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc
    })
  },
  watch: {
    splitDataArr (val) { // 监听receiverlist的变化 用以停止currentRInfo 的websocket 防止数据获取不到
      let peerIdsLenght = 0
      val.map(item => {
        if (item.isVideoDom) {
          peerIdsLenght++
        }
      })
      if (peerIdsLenght > 0) { // 开启websocket
        if (this.initCurrentInfoSocket) {
          this.createNewRWebsocket()

          this.initCurrentInfoSocket = false
        }
      } else { // 关闭websocket
        this.initCurrentInfoSocket = true
        this.stopNewRWebsocket()
        this.stopMataDataWebsocket()
      }

      if (peerIdsLenght > 0) {
        this.sendMataDataWebSocketOnce()
      } else {
        this.stopMataDataWebsocket()
      }
    }
  },
  methods: {
    ...mapMutations('common', [
      'CURRENT_RINFO',
      'DEVICELIST_VOLUMECHARTDATA',
      'DEVICELIST_SLOTINFODATA',
      'DEVICELIST_RECEIVERDATADATA',
      'DEVICELIST_CURRENTRINFODATA',
      'DEVICELIST_SETINFODATA'
    ]),
    initParam () {
      // 初始化参数
      this.filter.pageNum = 1
      this.filter.condition = ''
      this.filterspaceDevice.condition = ''
      this.checkedCities = ['live', 'online', 'offline']
      this.filterOptionObj = ['live', 'online', 'offline']
      this.currentSpaceInfo = {}
      this.splitIndex = 25
      this.checkAll = true
      this.sort = true
      this.filterspaceDevice.sort = 'name'
      this.filterspaceDevice.offline = true
      this.filterspaceDevice.online = true
      this.filterspaceDevice.live = true
      this.filter.offline = true
      this.filter.online = true
      this.filter.live = true
    },
    changeRouter (val) {
      this.isWorkspace = false
      this.initParam()
      let event = ''
      if (val == 'default') { // 所有的设备信息
        this.filter.pageSize = 25
        this.getDevice()
        event = 'All'
      } else if (val == 'manageWorkspace') { // space管理
        this.manageDeviceAndSpace = true
        this.isManageWorkspace = true
        event = 'Manage'
      } else { // worspace信息
        this.isWorkspace = true
        this.filterspaceDevice.id = val.id
        this.currentSpaceInfo = val
        event = 'Workpace'
        if (val.layout == '2x2') {
          this.splitIndex = 4
        } else if (val.layout == '2x3') {
          this.splitIndex = 6
        } else if (val.layout == '3x3') {
          this.splitIndex = 9
        } else if (val.layout == '3x4') {
          this.splitIndex = 9
        } else if (val.layout == '4x4') {
          this.splitIndex = 16
        } else if (val.layout == '5x5') {
          this.splitIndex = 25
        }
        this.getWorkspaceDevice()
      }

      multiviewPlusAnalyze(`mvpPageTo${event}`, this.$route.path)
    },
    showHideVi (val) {
      if (val) {
        this.getWorkspace()
      }
    },
    // 拖拽结束时的回调函数
    async changeHandleHomeSort (val) {
      const newIndex = val.moved.newIndex
      let moveDevicePo = 0
      let leftPosition = 0
      if (newIndex != 0) {
        leftPosition = this.splitDataArr[newIndex - 1].position
      }
      let rightPositioin = 0
      let isLast = false // 是否是移动到了最后一个
      if (newIndex + 1 == this.splitDataArr.length) { // 移动到了最后一个
        rightPositioin = leftPosition + 200
        isLast = true
      } else {
        rightPositioin = this.splitDataArr[newIndex + 1].position
      }
      if (isLast) {
        moveDevicePo = rightPositioin
      } else {
        moveDevicePo = (leftPosition + rightPositioin) / 2
      }
      this.splitDataArr[newIndex].position = moveDevicePo
      const param = {
        deviceId: this.splitDataArr[newIndex].peerId,
        workspaceId: this.currentSpaceInfo.id,
        position: moveDevicePo
      }
      this.$http.post('/ccp/tvugm/workspaceDevice/edit', param)
    },
    async initPosition (url) {
      return new Promise((resolve) => {
        this.$http.post(url).then(res => {
          resolve(res)
        }).catch(() => {})
      })
    },

    search (val) { // 搜索device
      this.filter.condition = val
      this.filter.pageNum = 1
      this.getDevice()
    },
    searchSpaceDevice (val) {
      // 搜索space
      this.filterspaceDevice.condition = val
      this.getWorkspaceDevice()
    },
    getBehaviour () { // 初始化数据
      this.axios.get('/ccp/tvugm/userDevice/queryUserBehaviorForMv').then(res => {
        if (res.data.errorCode == '0x0') {
          this.initCurrentInfoSocket = false// 监听peerId停止
          this.isInit = true
        } else {
          console.log('interface Error... ' + res.data) // 打印,看下复制网页是什么问题
        }
      }).catch(() => {})
    },

    getDevice () {
      // 获取设备列表
      this.loading = true
      // this.filter.condition = 'CS_V5_R'
      this.axios.post('/ccp/tvugm/workspaceDevice/availableDevice', this.filter).then(res => {
        if (res.data.errorCode == '0x0') {
          // splitDataArr
          $('.receiverLists').scrollTop(0)
          const splitArrData = res.data.result.list
          const splitJsonData = splitArrData
          this.splitDataArr = []
          this.onlineSDIPeerId = []
          this.ajaxR = []
          splitJsonData.map(item => {
            item.isVideoDom = true// 将有视频的添上标志
            this.splitDataArr.push(item)
          })
          const splitJsonDataL = splitJsonData.length
          for (let i = splitJsonDataL; i < this.splitIndex; i++) { // 将其余的空间 使用占位符补上
            const videoStation = { // 占位符,没有视频的占地空间
              isVideoDom: false
            }
            this.splitDataArr.push(videoStation)
          }
          this.splitDataArr = this.splitDataArr.concat([])
          this.total = res.data.result.total
          if (res.data.result.list.length == 0) {
            this.filter.pageNum = 0
          } else {
            this.filter.pageNum = res.data.result.pageNum
          }

          this.sendWebsocket()
          this.createNewRWebsocket()
        }
        this.$nextTick(() => {
          this.handleResize()
        })
        this.loading = false
      }).catch(() => {})
    },
    getWorkspace () {
      this.loadingSpaceList = true
      // 获取设备列表
      this.axios.post('/ccp/tvugm/workspace/page', this.filterWorkspace).then(res => {
        this.loadingSpaceList = false
        if (res.data.errorCode == '0x0') {
          // splitDataArr
          this.workspaceList = res.data.result.list
        }
      }).catch(res => {
        this.loadingSpaceList = false
      })
    },
    getWorkspaceDevice () {
      // 无分页
      this.loading = true
      this.axios.post('/ccp/tvugm/workspaceDevice/currentDevice', this.filterspaceDevice).then(res => {
        if (res.data.errorCode == '0x0') {
          // splitDataArr
          const splitArrData = res.data.result
          const splitJsonData = splitArrData
          this.splitDataArr = []
          splitJsonData.map(item => {
            item.isVideoDom = true// 将有视频的添上标志
            this.splitDataArr.push(item)
          })
          const splitJsonDataL = splitJsonData.length
          for (let i = splitJsonDataL; i < this.splitIndex; i++) { // 将其余的空间 使用占位符补上
            const videoStation = { // 占位符,没有视频的占地空间
              isVideoDom: false
            }
            this.splitDataArr.push(videoStation)
          }
          this.splitDataArr = this.splitDataArr.concat([])
          this.total = res.data.result.total
          this.$nextTick(() => {
            this.handleResize()
          })
        }
        this.loading = false
      }).catch(() => {})
    },
    // 分页
    handleCurrentChange (val) {
      this.filter.pageNum = val
      this.getDevice()
    },
    handleSizeChange (val) {
      this.filter.pageSize = val
      this.filter.pageNum = 1
      this.getDevice()
    },
    sortChange () { // sort筛选框的回调方法
      let event = ''
      this.sortIsActive = !this.sortIsActive
      if (this.sortIsActive) {
        this.filterspaceDevice.sort = 'name'
        event = 'Name'
      } else {
        this.filterspaceDevice.sort = ''
        event = 'UnName'
      }
      multiviewPlusAnalyze(`mvpSort${event}`, this.$route.path)
      this.getWorkspaceDevice() // 每次筛选时调用queryRlist方法
    },

    manageGridSources () {
      this.manageDeviceAndSpace = true
      this.isManageWorkspace = false
    },
    hideManage () {
      this.workspaceListCurrent = this.currentSpaceInfo
      this.manageDeviceAndSpace = false
      this.isManageWorkspace = false
      this.getWorkspaceDevice()
    },
    changeShowInfo () { // show info 函数的控制
      let event = ''
      if (this.showInfo) { // showInfo打开control module显示
        Bus.$emit('controlModuleChange', false)
        event = 'Open'
      } else {
        Bus.$emit('controlModuleChange', true)
        event = 'Close'
      }
      const sendMsg = {
        pn: 'mv',
        type: 'showInfo',
        content: '',
        remark: 'showInfo'
      }
      const content = {
        showInfo: this.showInfo
      }
      sendMsg.content = content
      multiviewPlusAnalyze(`mvpShowDescrible${event}`, this.$route.path)
    },
    handleResize () {
      const splitBorder = $('.left.splitBorder').width()
      $('.left.splitBorder').css('height', splitBorder * 212 / 363)
    },
    changeSplit (index) {
      // 切换分屏
      if (this.isWorkspace) {
        // 如果是space里面的 不能切换分屏
        return false
      } else {
        if (this.splitIndex == index) { // 如果点击的同一个 禁止
          return false
        }
        this.splitIndex = index
        this.filter.pageSize = index
        this.filter.pageNum = 1
        this.getDevice()
        this.$nextTick(() => {
          this.handleResize()
        })
        const event = index
        multiviewPlusAnalyze(`mvpSpliteScreen${event}`, this.$route.path)
      }
    },
    fullScreen (val) { // 全屏
      let event = ''
      if (val) {
        $('#headerBox').slideUp()
        $('.el-main.scrollbar').css('height', '100%')
        $('.bk-voip-select').slideUp(() => {
          $('.mv-content').css('paddingTop', '20px')
          $('#pro_nav_icon_bg').hide()
          this.showFullScreen = true
        })
        event = 'Open'
      } else {
        $('.el-main.scrollbar').css('height', 'calc(100% - 64px)')
        this.showFullScreen = false
        $('#headerBox').slideDown()
        $('#pro_nav_icon_bg').show()
        $('.mv-content').css('paddingTop', '0')
        $('.bk-voip-select').slideDown()
        event = 'Close'
        this.$nextTick(() => {
          $('.bkcontent').css('width', 200)
        })
      }
      $('.currentVoip').removeClass('currentVoipLength')
      if (this.voipWitchIcon != 0) {
        this.voipWitchIcon = 1
      }
      this.voipDom = false
      multiviewPlusAnalyze(`mvpFullScreen${event}`, this.$route.path)
    },
    goToMultiview () {
      this.$router.push({ name: 'MultiView' })
      multiviewPlusAnalyze('multiviewGoToMultiview', this.$route.path)
    },
    handleCheckAllChange (val) { // 全选框的回调事件
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
      this.filter.pageNum = 1
      if (this.checkAll) { // 判断这个全选的框是否被选中然后给接口传参
        if (this.isWorkspace) {
          this.filterspaceDevice.offline = true // queryRlist的接口参数
          this.filterspaceDevice.online = true // queryRlist的接口参数
          this.filterspaceDevice.live = true // queryRlist的接口参数
          multiviewPlusAnalyze('mvpWorkspaceSelectAll', this.$route.path) // 谷歌统计 alldevice中是否全选是否全选
        } else {
          this.filter.offline = true // queryRlist的接口参数
          this.filter.online = true // queryRlist的接口参数
          this.filter.live = true // queryRlist的接口参数
          multiviewPlusAnalyze('mvpAllDeviceSelectAll', this.$route.path) // 谷歌统计 alldevice中是否全选是否全选
        }
      } else {
        if (this.isWorkspace) {
          this.filterspaceDevice.offline = false
          this.filterspaceDevice.online = false
          this.filterspaceDevice.live = false
          multiviewPlusAnalyze('mvpWorkspaceSelectCancel', this.$route.path) // 谷歌统计 alldevice中是否全选是否全选
        } else {
          this.filter.offline = false
          this.filter.online = false
          this.filter.live = false
          multiviewPlusAnalyze('mvpAllDeviceSelectCancel', this.$route.path) // 谷歌统计 alldevice中是否全选是否全选
        }
      }

      if (this.isWorkspace) {
        this.getWorkspaceDevice()
      } else {
        this.getDevice() // 每次筛选时调用getDevice方法
      }
    },
    handleCheckedCitiesChange (value) { // 子组的筛选框
      const checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.filter.pageNum = 1
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
      const statusArr = value // 通过索引去把每次筛选的条件传给queryRlist这个接口
      let event = ''
      if (statusArr.indexOf('live') > -1) { // 控制live的参数
        if (this.isWorkspace) {
          this.filterspaceDevice.live = true
        } else {
          this.filter.live = true
        }
        event = 'LiveSelected'
      } else {
        if (this.isWorkspace) {
          this.filterspaceDevice.live = false
        } else {
          this.filter.live = false
        }
        event = 'LiveUnSelected'
      }
      if (statusArr.indexOf('online') > -1) { // 控制online的参数
        if (this.isWorkspace) {
          this.filterspaceDevice.online = true
        } else {
          this.filter.online = true
        }
        event = 'OnlineSelected'
      } else {
        if (this.isWorkspace) {
          this.filterspaceDevice.online = false
        } else {
          this.filter.online = false
        }
        event = 'OnlineUnSelected'
      }

      if (statusArr.indexOf('offline') > -1) { // 控制offline的参数
        if (this.isWorkspace) {
          this.filterspaceDevice.offline = true
        } else {
          this.filter.offline = true
        }
        event = 'OfflineSelected'
      } else {
        if (this.isWorkspace) {
          this.filterspaceDevice.offline = false
        } else {
          this.filter.offline = false
        }
        event = 'OfflineUnSelected'
      }
      let witchEvent = ''
      if (this.isWorkspace) {
        this.getWorkspaceDevice()
        witchEvent = 'mvpWorkspace'
      } else {
        witchEvent = 'mvpAll'

        this.getDevice() // 每次筛选时调用getDevice方法
      }
      multiviewPlusAnalyze(`${witchEvent}${event}`, this.$route.path) // 谷歌统计 alldevice中是否全选是否全选
    },
    // 根据版本区分online R通过什么方式获取SDI
    getOnlineSDIStatus (data) {
      this.ajaxR = []
      data.map(item => {
        if (item.status == 1) {
          const index = item.version.indexOf('.')
          item.version = item.version.slice(0, index + 2)
          if (!(item.version * 1000 >= 7500)) {
            this.ajaxR.push(item.peerId)
          }
        }
      })
      this.getLowVSDIINData()
    },
    // 通过ajax获取SDI IN
    getLowVSDIINData () {
      let removeSDI = []
      // 设备状态由online变为非online
      this.onlineSDIPeerId.map((v, i) => {
        if (!this.ajaxR.includes(v)) {
          removeSDI.push(i)
        }
      })
      // 上次已经获取online SDI的peerId
      removeSDI.map((v, i) => {
        this.onlineSDIPeerId.splice(v - i, 1)
      })
      let newPeerId = []
      this.ajaxR.map(v => {
        if (!this.onlineSDIPeerId.includes(v)) { // 上次已经获取的，不必重新获取
          this.onlineSDIPeerId.push(v)
          newPeerId.push(v)
        }
      })
      if (!newPeerId.length) return
      this.axios.post('/ccp/tvucc-device/receiver/queryConfig', { rid: newPeerId.join(',') })
        .then(res => {
          if (res.data.errorCode != '0x0') return
          res.data.result = res.data.result || {}
          this.lowVersionOnlineR = []
          for (const key in res.data.result) {
            this.allRSocketInfo[key].sdi = true
            if (res.data.result[key]) {
              const data = {
                WorkMode: 1,
                SDI: true,
                WebRSDIFeedUrl: res.data.result[key],
                WebRVideoSolution: 300
              }
              const obj = {
                data: JSON.stringify(data),
                rid: key
              }
              this.lowVersionOnlineR[key] = data
              this.updateDeviceDetailData('deviceListCurrentRInfoData', 'DEVICELIST_CURRENTRINFODATA', obj)
            }
          }
        }).catch(() => {})
    },
    sendWebsocket (data) { // 更新设备状态
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_Mv_R') // 停止websocket
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_Mv_R',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_Mv_R',
        success: res => {
          // 更新websocket参数
          const data = res.result
          let assemblyData = []
          data.map(item => {
            assemblyData[item.peerId] = item
            const curDevice = this.allRSocketInfo[item.peerId]
            assemblyData[item.peerId].sdi = curDevice && curDevice.sdi
          })
          this.canVoipPlay = true
          this.allRSocketInfo = assemblyData
          let newParam = []
          this.splitDataArr.map(item => {
            if (item.isVideoDom) {
              newParam.push(item.peerId)
            }
          })
          this.peerIds = newParam
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_Mv_R',
            data: { peerIds: this.peerIds.join(',') }
          }

          this.pageRequest.sendMsgs.taskInfo_Mv_R.message = taskInfoMsg
          // }
          this.getOnlineSDIStatus(data) // 更新SDI-IN参数
        }
      })
    },
    createNewRWebsocket () { // 创建currentRInfo websocket
      this.stopNewRWebsocket()
      this.sendMsgWebsocket('VolumeChart', '2152867840', '200', 200) // 获取音量信息
      this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000)
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000)
    },
    stopNewRWebsocket () { // 停止currentRInfo websocket
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'receiverData', 'currentRInfo', 'setInfo']) // 停止websocket
      }
    },
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.peerIds.join(',')
        },
        time: time || 1000,
        key: typeVal || type,
        success: res => {
          const pageRListInfo = this.pageRWebsocket.sendMsgs[type.charAt(0).toLowerCase() + type.slice(1)]
          if (pageRListInfo && !this.selectRIng) {
            let peerIdsStr = []
            this.splitDataArr.map(item => {
              if (item.isVideoDom) {
                peerIdsStr.push(item.peerId)
              }
            })
            this.peerIds = peerIdsStr
            pageRListInfo.message = {
              categoryId: categoryId,
              operationType: operationType,
              rid: this.peerIds.join(',')
            }
          }
          if (type == 'CurrentRInfo') {
            const curDevice = this.allRSocketInfo[res.rid]
            if (curDevice && curDevice.version) {
              const index = curDevice.version.indexOf('.')
              curDevice.version = curDevice.version.slice(0, index + 2)
            }
            if (curDevice && type == 'CurrentRInfo' && curDevice.status == 1 && curDevice.version * 1000 >= 7500 && JSON.parse(res.data).WebRSDIFeedUrl) {
              this.allRSocketInfo[res.rid].sdi = true
              const data = JSON.parse(res.data)
              data.WorkMode = 1
              data.SDI = true
              res.data = JSON.stringify(data)
            }
            if (this.ajaxR.includes(res.rid)) { // 从queryConfig获取的SDI IN流信息，不能让webscoket覆盖掉
              if (this.lowVersionOnlineR[res.rid]) {
                const data = JSON.parse(res.data)
                for (const key in this.lowVersionOnlineR[res.rid]) {
                  data[key] = this.lowVersionOnlineR[res.rid][key] || data[key]
                }
                res.data = JSON.stringify(data)
              }
            }
          }
          this.updateDeviceDetailData(`deviceList${type}Data`, `DEVICELIST_${type.toUpperCase()}DATA`, res)
        }
      })
    },
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      let peerIdsStr = []
      this.splitDataArr.map(item => {
        if (item.isVideoDom) {
          peerIdsStr.push(item.peerId)
        }
      })
      this.peerIds = peerIdsStr
      this.States[deviceData].map((v, i) => {
        if (this.peerIds.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },

    sendMataDataWebsocket () {
      let sendParam = []
      if (this.splitDataArr) {
        this.splitDataArr.map(item => {
          if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
            sendParam.push(item.livePeerId)
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getMetadataList') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest.send({
        message: {
          type: 'getMetadataList',
          module: 'getMetadataList',
          data: { peerIds: sendParam.join(',') }
        },
        time: 30000,
        key: 'getMetadataList',
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).length > 0) {
                result = JSON.parse(data.result)
                this.mataData = result
              }
            }
          }

          let newParam = []
          if (this.splitDataArr) {
            this.splitDataArr.map(item => {
              if (this.allRSocketInfo[item.peerId] && this.allRSocketInfo[item.peerId].status == 2) {
                newParam.push(item.livePeerId)
              }
            })
          }
          newParam = [...new Set(newParam)]
          const taskInfoMsg = {
            type: 'getMetadataList',
            module: 'getMetadataList',
            data: { peerIds: newParam.join(',') }
          }
          this.pageRequest.sendMsgs.getMetadataList.message = taskInfoMsg
        }
      })
    },
    sendMataDataWebSocketOnce () { // 开始发送一次 以后没60s发送一次
      let sendParam = []
      if (this.splitDataArr) {
        this.splitDataArr.map(item => {
          if (item.status == 2) {
            sendParam.push(item.livePeerId)
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getMetadataList')
      }
      this.pageRequest.send({
        message: {
          type: 'getMetadataList',
          module: 'getMetadataList',
          data: { peerIds: sendParam.join(',') }
        },
        time: 0,
        key: 'getMetadataList',
        isOnce: true,
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).length > 0) {
                result = JSON.parse(data.result)
                this.mataData = result
              }
            }
          }

          if (this.$createWebsocket.pageRequest) {
            this.$createWebsocket.pageRequest('stop', 'getMetadataList')
          }
          this.sendMataDataWebsocket()
        }
      })
    },
    stopMataDataWebsocket () { // 停止currentRInfo websocket
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', 'getMetadataList') // 停止websocket
      }
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_Mv_R') // 停止websocket
    }
    this.stopNewRWebsocket()
    this.stopMataDataWebsocket()
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/css/mvAdaptive/index.less'; //响应式文件
::v-deep .el-select-dropdown__item {
  max-width: 178px;
}

::v-deep .el-checkbox__inner {
  border: 2px solid #999;
  background: none;
}

.btn_green {
  height: 32px;
  width: 120px;
  line-height: 32px;
  text-align: center;
  background: #444;
  border-radius: 4px;
  color: #909090;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.default {
  border-bottom: 1px solid #333;
}

.bottom {
  border-top: 1px solid #333;
}

#app {
  background: #252525;
}

.selectDevice {
  z-index: 19999 !important;
}

.sc::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.sc::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

.sc::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: none;
}

#app::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

#app::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

#app::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: none;
}

.fullClose {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.36rem;
  z-index: 10000000000;
}

.voipBoxBottom {
  width: 72px;
}

.mv-content {
  width: 100%;
  padding-left: 0.5%;
  height: 100% !important;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  min-width: 1024px !important;

  .setHei {
    /*height: 100%;*/
  }

  #voipBox {
    margin-left: 118px;
    float: left;
    position: relative;
    width: 1120px;
    height: 70px;
    top: -20px;
    z-index: 1001 !important;
    overflow: hidden;

    .voipCall {
      position: absolute;
      left: 0;
      top: 17px;
      z-index: 10;

      #callingNum {
        height: 15px;
        width: 15px;
        border-radius: 7px;
        background: #fff;
        color: red;
        position: absolute;
        right: -6px;
        top: 1px;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
      }

      .openCall {
        width: 36px;
        height: 36px;
        background: #33ab4f;
        border-radius: 18px;
        font-size: 26px;
        line-height: 36px;
        text-align: center;
        z-index: 10;
      }

      .numDis {
        width: 36px;
        height: 36px;
        background: #666;
        border-radius: 18px;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        z-index: 10;
      }

      .red {
        background: red;
      }
    }

    .currentVoipLength {
      transform: translate(0%) !important;
      opacity: 1 !important;
    }

    .currentVoip {
      height: 70px;
      border-radius: 6px;
      position: absolute;
      left: 18px;
      top: 0;
      background: #444;
      z-index: 1;
      transform: translate(-100%);
      transition: all 0.5s;
      opacity: 0;
      overflow: hidden;

      .width0 {
        min-width: 0 !important;
        width: 0 !important;
      }

      .voipBox {
        padding: 10px 10px 0px 40px;
        background: #444;
        border-radius: 8px 0 0 8px;
        max-width: 986px;
        min-width: 147px;
        height: 70px;
        border-right: none;
        display: block;
        overflow: hidden;

        .single-calling-source {
          padding-top: 6px;
          cursor: pointer;
        }
      }

      .control-big-box {
        padding: 10px 0;
        background: #444;
        height: 70px;
        box-sizing: border-box;
        border-radius: 0px 8px 8px 0;
        /*border: 1px solid #333;*/
        border-left: none;

        .control-call-box {
          border-left: 2px solid #666;
          height: 48px;
          width: 116px;
          box-sizing: border-box;
          padding: 6px 15px;

          .modifyVoipMember {
            float: left;
            box-sizing: border-box;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            color: #fff;
            position: relative;
            background: #33ab4f;
            margin-right: 12px;
            font-size: 0.2rem;
            line-height: 36px;
          }

          .stopAllViop {
            background: red;
            float: left;
            box-sizing: border-box;
            width: 36px;
            height: 36px;
            border-radius: 18px;
            color: #fff;
            position: relative;
            font-size: 0.2rem;
            line-height: 36px;
          }
        }
      }
    }
  }

  .fullScreenBorder {
    display: block !important;
    /*width: 100%;*/
    position: absolute;
    top: 4px;
    left: 0;
    padding: 32px 20px 0 0;
    /*z-index: 1001;*/
    overflow: hidden;

    #voipBox {
      margin-left: 0;
    }

    .fullScreen {
      position: absolute;
      right: 0;
      top: -10px;
      margin-left: 6px;
    }

    .fullScreen {
      font-size: 0.36rem;
    }
  }
  .paginationBox {
    margin-top: 0;
    line-height: 32px;
    height: 32px;
  }
  .bk-voip-select {
    height: auto;
    width: auto;
    padding: 25px 0;
    overflow: hidden;

    .filter_setting {
      margin: 0 0 0 80px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: rgba(204, 204, 204, 1);
      cursor: pointer;
      line-height: 32px;
    }

    .paginationDevice {
      padding-left: 45px;
    }

    .statusDevice {
      margin-left: 60px;

      .allStatus {
        line-height: 32px;
        margin: 0 10px;
      }

      .statusName {
        line-height: 32px;
      }

      .statusCheckBox {
        float: left;
        line-height: 32px;
      }

      .sortBox {
        line-height: 32px;
        padding-left: 45px;
      }
    }

    .searchDevice {
      line-height: 32px;
      margin-left: 24px;
    }

    .selectDown {
      max-width: 178px;
      height: 32px;
      line-height: 32px;

      ::v-deep .el-select {
        background: transparent;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .spaceName {
      line-height: 32px;
      padding-right: 10px;
    }

    .bkcontent {
      /*width: 200px;*/
      height: 30px;
      float: left;
      z-index: 1000000;
      position: relative;

      .selectTitle {
        width: 200px;
        height: 30px;
        line-height: 30px;
        background: #424242;
        border: none;
        cursor: pointer;
        border-radius: 6px;
        font-size: 14px;

        .triangleDown {
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid #fff;
          margin: 12px 10px 0 0;
        }
      }

      .mvBookMarkC {
        position: absolute;
        top: 32px;
      }
    }

    .splitScreen {
      width: auto;
      float: right;
      line-height: 32px;

      .sortName {
        float: left;
        font-size: 18px;
        line-height: 32px;
        padding: 0 15px;
        color: #666666;

        .active {
          color: #fff;
        }
      }

      .showInfo {
        float: left;
        line-height: 32px;

        ::v-deep .el-switch__core {
          background-color: #252525;
          border: 1px solid #666666;
        }
      }

      .splitScreenNumAc {
        margin-left: -6px !important;
      }

      .splitScreenNum {
        float: left;
        width: auto;
        margin-left: 0.38rem;

        .splitBtn {
          padding: 0;
          background: transparent;
          border: none;
          margin-top: 5px;
          display: inline-block;

          ::v-deep span {
            display: block;
            width: 100%;
            height: 100%;
          }

          ::v-deep i {
            width: 100%;
            height: 100%;
          }
        }

        .fullScreen {
          font-size: 0.3rem;
          margin-left: 6px;
        }

        .isAllow {
          cursor: not-allowed;
        }

        .split4 {
          margin-right: 6px;
        }

        i {
          font-size: 0.26rem;
          color: #666;
        }

        .splitActive {
          color: #dbdbdb;
        }
      }
    }
  }

  .isfull {
    max-height: 776px;
  }

  .receiverLists {
    height: calc(100% - 90px);
    background: #1b1b1b;
    box-sizing: border-box;
    margin-top: -20px;
    clear: both;
    /*overflow: hidden;*/
    overflow-y: scroll;
    overflow-x: hidden;

    .splitBorder {
      /*position: relative;*/
      z-index: 1;
      overflow: hidden;
    }

    .videosize-4 {
      height: 536px;
      width: 930px;
      padding: 15px;
    }

    .videosize-6 {
      width: 618px;
      height: 368px;
      padding: 10px 5px;
    }

    .videosize-9 {
      padding: 6px;
      width: 611px;
      height: 354px;
    }

    .videosize-16 {
      padding: 7px 8px;
      width: 455px;
      height: 266px;
    }

    .videosize-25 {
      padding: 5px;
      width: 363px;
      height: 212px;
    }
  }

  .view4-padding {
    padding: 5px 11px;
  }

  .view6-padding {
    padding: 5px 14px;
  }

  .view9-padding {
    padding: 9px 25px;
  }

  .view16-padding {
    padding: 8px 31px;
  }

  .view25-padding {
    padding: 10px 34px;
  }
}
</style>
